import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { textCenter } from "../../style/aligns";
import { request } from "../../api/request";
import { postBlog } from "../../api/endpoints/blogs";
import { postBlogHandler } from "../../api/handlers/postBlog";
import { MAX_CHARACTERS_PREVIEW } from "../../constants/blogInfo";
import { getContent } from "../../constants/languages/languages";
import topics from "../../constants/topics";
import styled from "styled-components";
import {
  gradientBg,
  secondaryColor,
  alternativeColor,
} from "../../style/colors";

import Info from "../../components/Screens/Solutions/Info";
import InputFile from "../../components/Utils/InputFile";
import UploadContentInfo from "./UploadContentInfo";
import CustomizableButton from "../../components/Buttons/CustomizableButton";
import LoadingSpinner from "../../components/Spinners/LoadingSpinner";

const getOptions = (options) => {
  return options.map((elem, index) => (
    <Option className="option-font" key={index} value={elem}>
      {elem}
    </Option>
  ));
};

const defaultTopic = "IA";

function UploadBlogScreen(props) {
  const titleRef = useRef();
  const urlRef = useRef();
  const imageRef = useRef();
  const hashtagRef = useRef();
  const contentRef = useRef();
  const passwordRef = useRef();
  const [topic, setTopic] = useState(defaultTopic);
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const [message, setMessage] = useState();
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);

  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);

  useEffect(() => {
    const clear = () => {
      setMessage(null);
      setSpinnerVisibility(false);
      titleRef.current.value = "";
      urlRef.current.value = "";
      hashtagRef.current.value = "";
      imageRef.current.value = null;
      contentRef.current.value = null;
      passwordRef.current.value = "";
      contentRef.current.setContent("");
      setTopic(defaultTopic);
      setImage();
      setPreview();
    };
    const timeoutId = setTimeout(clear, 2000);
    return () => clearTimeout(timeoutId);
  }, [message]);

  const toURL = () => {
    if (titleRef.current.value) {
      urlRef.current.value = titleRef.current.value
        .trim()
        .toLowerCase()
        .replace(/[\s]/g, "-")
        .replace("á", "a")
        .replace("é", "e")
        .replace("í", "i")
        .replace("ó", "o")
        .replace("ú", "u")
        .replace(/[^a-z0-9-]/g, "");
    }
  };

  const upload = async () => {
    setSpinnerVisibility(true);
    const content = contentRef.current.getContent();
    const preview = contentRef.current.getContent({ format: "text" });

    const body = new FormData();
    body.append("title", titleRef.current.value);
    body.append("url", urlRef.current.value);
    body.append("hashtags", hashtagRef.current.value);
    body.append("password", passwordRef.current.value);
    body.append("topic", topic);
    body.append("image", image);
    body.append("content", content);
    body.append(
      "preview",
      `${preview.substring(
        0,
        Math.min(preview.length, MAX_CHARACTERS_PREVIEW)
      )}...`
    );

    const { status } = await request(...postBlog(urlRef.current.value), body);

    const { OK, message } = postBlogHandler(status);
    if (OK) {
      setMessage(message);
    } else {
      setMessage(content.blogs.upload.error);
    }
  };

  return (
    <Wrapper id={"initial"}>
      <Info
        background={gradientBg}
        title={content.blogs.upload.title}
        titleClass="gothamBold font-title whiteColor"
        description={content.blogs.upload.description}
        descriptionClass="gothamLight font-text whiteColor"
        align={textCenter}
        padding={"3% 20%"}
        isNotP={false}
      />
      <div style={{ padding: "2% 15%" }}>
        <ContentWrapper>
          <div className="row">
            <div className="col-lg marginAll">
              {/* TITLE INPUT */}
              <Input
                ref={titleRef}
                className="innerInput font18 input97"
                placeholder={content.blogs.upload.inputs.title}
              />
            </div>
            <div className="col-lg marginAll">
              {/* TOPIC INPUT */}
              <Select
                className="innerInput font18 input97"
                defaultValue={topic}
                onChange={(event) => setTopic(event.target.value)}
                onFocus={(event) => setTopic(event.target.value)}
              >
                {getOptions(topics)}
              </Select>
            </div>
          </div>
          <div className="row">
            <div className="col-lg marginAll input97">
              {/* URL INPUT */}
              <Input
                ref={urlRef}
                className="innerInput font18 input97"
                placeholder={"URL"}
              />
            </div>
            <div className="col-lg marginAll input97">
              {/* AUTO URL */}
              <CustomizableButton
                id={0}
                font={"gothamBold font20 whiteColor"}
                title={content.blogs.upload.inputs.autoURL}
                isCallback={true}
                callBack={toURL}
                background={secondaryColor}
                border={"none"}
                hover={{
                  border: "none",
                  background: alternativeColor,
                }}
                margin={"0%"}
                width={"100%"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg marginAll">
              {/* HASHTAGS INPUT */}
              <Input
                ref={hashtagRef}
                className="innerInput font18 input97"
                placeholder={"Hashtags # (happy, hashtag, science)"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg marginAll input97">
              {/* IMAGE INPUT */}
              <InputFile
                title={content.blogs.upload.inputs.image}
                innerRef={imageRef}
                setFile={setImage}
                setPreview={setPreview}
              />
            </div>
            <div className="col-lg marginAll input97">
              {preview && (
                <img
                  title="preview"
                  width="auto"
                  height="120px"
                  src={preview}
                  alt="preview"
                />
              )}
            </div>
          </div>
        </ContentWrapper>
        <UploadContentInfo innerRef={contentRef} />
        <br />
        <div className="row">
          <div className="col-lg marginAll input97">
            <div style={{ margin: "2% 0" }}>
              {/* PASSWORD INPUT */}
              <Input
                ref={passwordRef}
                className="innerInput font18"
                placeholder={content.blogs.upload.inputs.password}
              />
            </div>
            <CustomizableButton
              id={0}
              font={"gothamBold font20 whiteColor"}
              title={content.blogs.upload.inputs.button}
              isCallback={true}
              callBack={upload}
              background={secondaryColor}
              border={"none"}
              hover={{
                border: "none",
                background: alternativeColor,
              }}
              margin={"0%"}
              width={"100%"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg marginAll input97">
            {spinnerVisibility &&
              (message ? <h3>{message}</h3> : <LoadingSpinner />)}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0 5% 0;
`;
const ContentWrapper = styled.div`
  border: 3px solid ${alternativeColor};
  border-radius: 50px;
  padding: 5%;
  margin-bottom: 3%;
`;
const Input = styled.input`
  border: 1px solid gray;
  border-radius: 0.6rem;
  padding: 1%;
  width: 100%;
  font-family: "Gotham-Light";
`;
const Select = styled.select`
  border-radius: 0.6rem;
  background: white;
  padding: 1%;
  width: 100%;
  border: 1px solid gray;
  font-family: "Gotham-Light";
`;
const Option = styled.option`
  border-radius: 0.6rem;
  padding: 1%;
  width: 100%;
  font-family: "Gotham-Light";
`;

export default UploadBlogScreen;
