import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBlogs } from "../../app/slices/blogsSlice";
import { request } from "../../api/request";
import { getBlogs } from "../../api/endpoints/blogs";
import { whiteBg } from "../../style/colors";
import { textLeft } from "../../style/aligns";
import { OK } from "../../api/statusCodes";
import { alternativeColor } from "../../style/colors";
import { getContent } from "../../constants/languages/languages";
import metadata from "../../constants/metadata";

import styled from "styled-components";
import InfoImage from "../../components/Screens/Solutions/InfoImage";
import LoadingSpinner from "../../components/Spinners/LoadingSpinner";
import BlogPreview from "../../components/Blogs/BlogPreview";
import LineDivider from "../../components/Utils/LineDivider";
import SEO from "../../components/SEO";

import Img1 from "./../../assets/WeAreLambda/icon2.webp";

function BlogScreen(props) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const blogs = useSelector((state) => state.blogs.list);
  const [spinnerVisibility, setSpinnerVisibility] = useState(!blogs);
  const content = getContent(language);

  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);

  // SEND REQUEST ----------------------------------------
  useEffect(() => {
    async function fetchData() {
      if (!blogs) {
        const { status, data } = await request(...getBlogs());
        if (status === OK) {
          dispatch(setBlogs(data.blogs));
        }
        setSpinnerVisibility(false);
      }
    }
    fetchData();
  }, [dispatch, blogs, language]);

  const banners = [
    {
      id: 1,
      background: whiteBg,
      txtAlign: textLeft,
      title: content.blogs.notFoundScreen.title,
      description: (
        <div>
          <br />
          <p className={"gothamLight font-text blackColor"}>
            {content.blogs.notFoundScreen.content[0]}
          </p>
          <br />
          <p className={"gothamLight font-text blackColor"}>
            {content.blogs.notFoundScreen.content[1]}
          </p>
        </div>
      ),
      rightImg: true,
      img: Img1,
      titleClass: `gothamBold font-title primaryColor ${textLeft}`,
    },
  ];

  if (spinnerVisibility) {
    return <LoadingSpinner />;
  }

  if (blogs && blogs.length !== 0) {
    return (
      <Wrapper id={"initial"}>
        <SEO metadata={metadata.blog} />
        <div style={{ padding: "1% 15%" }}>
          <LineDivider
            width="100%"
            padding="2% 0"
            border={`3px solid ${alternativeColor}`}
          />
          {blogs.map((elem, index) => {
            const hashtags = elem.hashtags.map((e) => `#${e}`);
            return (
              <div key={index}>
                <BlogPreview
                  title={elem.title}
                  url={elem.url}
                  topic={elem.topic}
                  hashtags={hashtags}
                  image={elem.image.$oid}
                  date={elem.creation_date}
                  preview={elem.preview}
                />
                <LineDivider
                  width="100%"
                  padding="2% 0"
                  border={`3px solid ${alternativeColor}`}
                />
              </div>
            );
          })}
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper id={"initial"}>
      <SEO metadata={metadata.blog} />
      {banners.map((elem) => {
        return (
          <InfoImage
            key={elem.id}
            id={elem.id}
            background={elem.background}
            txtAlign={elem.txtAlign}
            title={elem.title}
            description={elem.description}
            rightImg={elem.rightImg}
            img={elem.img}
            titleClass={elem.titleClass}
            imgPadding={{ padding: "10%" }}
          />
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0 5% 0;
`;

export default BlogScreen;
