function InputFile({
  title,
  innerRef,
  setFile,
  setPreview,
  multiSet,
  index,
  files,
  previews,
}) {
  const fileHandler = (e) => {
    const selectedFile = e.target.files[0];
    if (multiSet) {
      const newFiles = files.slice();
      const newPreviews = previews.slice();
      newFiles[index] = selectedFile;
      newPreviews[index] = URL.createObjectURL(selectedFile);
      setFile(newFiles);
      setPreview(newPreviews);
      return;
    }
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  return (
    <>
      <div className="pl-lg-4">
        <div className="row">
          <div className="col">
            <h4 className="heading-small text-muted mb-4">{title}</h4>
            <input
              ref={innerRef}
              className="innerInput fontText input97"
              type="file"
              onChange={fileHandler}
            ></input>
          </div>
        </div>
      </div>
    </>
  );
}

export default InputFile;
