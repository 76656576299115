import { useNavigate } from "react-router-dom";
import { useState } from "react";

function CustomizableButton(props) {
  const navigate = useNavigate();

  const id = props.id;
  const border = props.border;
  const font = props.font;
  const title = props.title;
  const background = props.background;
  const hover = props.hover;

  const to = props.to;
  const replace = props.replace;
  const callback = props.callBack;
  const margin = props.margin;
  const width = props.width;
  const padding = props.padding;

  // ONLY FOR DROPDOWN MENU
  const func = props.func;
  const toggleId = props.toggleId;

  const style = {
    border: border,
    background: background,
    padding: padding ? padding : "1%",
    margin: margin ? margin : "3%",
    width: width ?? width,
  };

  const hoverStyle = {
    color: hover.color ?? hover.color,
    border: hover.border,
    background: hover.background,
    padding: padding ? padding : "1%",
    margin: margin ? margin : "3%",
    width: width ?? width,
  };

  const [_style, setStyle] = useState(style);

  const onClickFunction = (event) => {
    event.preventDefault();
    if (props.isIdle) return;
    if (props.isCallback) {
      callback(id);
    } else {
      navigate(to, { replace: replace });
    }
  };

  const defaultStyleFunction = () => {
    if (func) {
      func(toggleId, false);
    }
    setStyle(style);
  };

  const hoverStyleFunction = () => {
    if (func) {
      func(toggleId, true);
    }
    setStyle(hoverStyle);
  };

  return (
    <button
      className={font + " col-md radius8"}
      style={_style}
      onClick={onClickFunction}
      onMouseEnter={hoverStyleFunction}
      onMouseLeave={defaultStyleFunction}
    >
      {title}
    </button>
  );
}

export default CustomizableButton;
