const topics = [
  "IA",
  "Machine Learning",
  "Deep Learning",
  "Data Analyst",
  "Data Science",
  "Data Engineering",
];

export default topics;
