import { useSelector } from "react-redux";
import { getContent } from "../../../constants/languages/languages";

function Introduction(props) {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);
  return (
    <div
      className="whiteBg mobile-section-vertical-padding mobile-section-horizontal-padding"
      style={{ padding: "3% 18%" }}
    >
      <p className="gothamBold extraBold font-title primaryColor textCenter">
        {content.home.introduction.beforePotential}{" "}
        <span className="secondaryColor">
          {content.home.introduction.potential}{" "}
        </span>
        {content.home.introduction.afterPotential}
      </p>
      <div className="br-mobile" />
      <p className="gothamLight font-text blackColor textCenter">
        {content.home.introduction.content}
      </p>
    </div>
  );
}

export default Introduction;
