import { Editor } from "@tinymce/tinymce-react";

function UploadContentInfo({ innerRef }) {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINIMCE_API_KEY}
      onInit={(evt, editor) => (innerRef.current = editor)}
      initialValue=""
      init={{
        height: 500,
        menubar: true,
        plugins: [
          "anchor",
          "autolink",
          "charmap",
          "codesample",
          "emoticons",
          "image",
          "link",
          "lists",
          "media",
          "searchreplace",
          "table",
          "visualblocks",
          "wordcount",
          "checklist",
          "mediaembed",
          "casechange",
          "export",
          "formatpainter",
          "pageembed",
          "linkchecker",
          "a11ychecker",
          "permanentpen",
          "powerpaste",
          "advtable",
          "advcode",
          "editimage",
          "tableofcontents",
          "footnotes",
          "mergetags",
          "autocorrect",
          "typography",
          "inlinecss",
        ],
        toolbar: `undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | 
          link image media table mergetags | addcomment showcomments | align lineheight |
          checklist numlist bullist indent outdent | emoticons charmap | removeformat`,
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  );
}

export default UploadContentInfo;
