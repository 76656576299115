import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../api/config";
import { blogSectionURL } from "../../api/request";

function BlogPreview({ title, url, topic, hashtags, image, date, preview }) {
  const navigate = useNavigate();
  const [color, setColor] = useState("primaryColor");

  return (
    <div className="row" style={{ width: "100%" }}>
      <div
        className="col-lg d-flex justify-content-center align-content-center"
        style={{
          margin: "0px",
          padding: "0px",
          maxWidth: "300px",
          minWidth: "300px",
        }}
      >
        <img
          title={title}
          className="col-lg d-flex pointer blog-image"
          src={`${baseURL}${blogSectionURL}/images/${image}`}
          alt={title}
          width="auto"
          height="auto"
          loading="lazy"
          onClick={() => {
            navigate(url);
          }}
        />
      </div>

      <div
        className="col-lg d-flex justify-content-center text-align-left"
        style={{ padding: "0px", margin: "1% 2%" }}
      >
        <div className="row" style={{ width: "100%" }}>
          <h1
            className={`gothamBold font-subtitle pointer ${color}`}
            onClick={() => {
              navigate(url);
            }}
            onMouseEnter={() => {
              setColor("alternativeColor");
            }}
            onMouseLeave={() => {
              setColor("primaryColor");
            }}
          >
            {title}
          </h1>
          <div className="gothamLight font-hashtag">{`${topic} | ${hashtags.join(
            " "
          )} | ${date}`}</div>
          <div className="gothamLight font-text">{preview}</div>
        </div>
      </div>
    </div>
  );
}

export default BlogPreview;
