import { FileAPI } from "./config";
import { HTTP_METHODS } from "./config";

export const blogSectionURL = "blogs";

export const request = async (method, baseSectionURL, route, body) => {
  const api = FileAPI();
  const { status, data } = await call(
    api,
    method,
    baseSectionURL,
    route,
    body
  ).catch((err) => {
    try {
      return { status: err.response.status, data };
    } catch (error) {
      return { status: 502, data: null };
    }
  });
  return { status, data };
};

function call(api, method, baseSectionURL, route, body) {
  switch (method) {
    case HTTP_METHODS.GET:
      return api.get(`${baseSectionURL}${route}`, body);
    case HTTP_METHODS.POST:
      return api.post(`${baseSectionURL}${route}`, body);
    case HTTP_METHODS.PUT:
      return api.put(`${baseSectionURL}${route}`, body);
    case HTTP_METHODS.DELETE:
      return api.delete(`${baseSectionURL}${route}`, body);
    default:
      break;
  }
}
