import { useSelector } from "react-redux";
import { baseURL } from "../../api/config";
import { blogSectionURL } from "../../api/request";
import { alternativeColor } from "../../style/colors";
import { getContent } from "../../constants/languages/languages";
import LineDivider from "../Utils/LineDivider";

function BlogTitle({ title, topic, hashtags, image, date }) {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);
  return (
    <div className="row" style={{ width: "100%" }}>
      <div className="gothamBold font-title">{title}</div>

      <div className="gothamLight font-hashtag">{`${topic} | ${hashtags.join(
        " "
      )}`}</div>
      <div className="gothamLight font-text">
        {content.blogs.title.date}: {date}
      </div>
      <LineDivider
        width="5%"
        padding="2% 1%"
        border={`3px solid ${alternativeColor}`}
      />
      <div className="d-flex align-items-center justify-content-center">
        <img
          title={title}
          width={"60%"}
          height="auto"
          src={`${baseURL}${blogSectionURL}/images/${image}`}
          alt={title}
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default BlogTitle;
