import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../constants/languages/languages";
import styled from "styled-components";

// Facebook Pixel
import { leadEvent } from "../../constants/facebookPixelEvent";

// Assets
import CloseIcon from "../../assets/svg/CloseIcon";

export default function DataSent({ dataSentOpen, toggleDataSent }) {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  useEffect(() => {
    if (dataSentOpen) {
      leadEvent();
    }
  }, [dataSentOpen]);

  return (
    <Wrapper
      className="animate greenBg"
      dataSentOpen={dataSentOpen}
      style={
        dataSentOpen
          ? {
              minWidth: "22%",
              overflow: "hidden",
              overflowX: "hidden",
            }
          : {
              overflow: "hidden",
              overflowX: "hidden",
            }
      }
    >
      <SidebarHeader className="flexSpaceCenter">
        <p className="pirulen font-title greenColor">LAMBDA</p>
        <CloseBtn
          onClick={() => toggleDataSent(!dataSentOpen)}
          className="animate pointer"
          style={{ padding: "0px", minHeight: "50%" }}
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>
      <br />
      <p className="gothamLight font-text greenColor textCenter">
        {content.messageSent.success}
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 40%;
  height: auto;
  position: fixed;
  top: 30%;
  padding: 1%;
  border-radius: 1rem;
  left: ${(props) => (props.dataSentOpen ? "30%" : "-200%")};
  z-index: 9999;
  @media (max-width: 859px) {
    padding: 1.5rem;
    width: 96%;
    left: ${(props) => (props.dataSentOpen ? "2%" : "-200%")};
  }
`;
const SidebarHeader = styled.div`
  padding: 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
