import { createSlice } from "@reduxjs/toolkit";
import { selectLanguage } from "../../constants/languages/languages";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    language:
      localStorage.getItem("language") || selectLanguage(navigator.languages),
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

// Action creators are generated for each case of reducer function
export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
