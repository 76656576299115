import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LineDivider from "../../Utils/LineDivider";
import { alternativeColor } from "../../../style/colors";

export default function DropDown({
  submenus,
  DropDownOpen,
  toggleDropDownOpen,
  toggleId,
}) {
  const [y, setY] = useState(window.scrollY);
  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);
  return (
    <Wrapper
      className="primaryBg"
      DropDownOpen={DropDownOpen}
      y={y}
      onMouseEnter={() => toggleDropDownOpen(toggleId, true)}
      onMouseLeave={() => toggleDropDownOpen(toggleId, false)}
    >
      {submenus.map((submenu, index) => (
        <div key={index}>
          {index === 4 && (
            <LineDivider
              width="100%"
              padding="5% 0"
              border={`3px solid ${alternativeColor}`}
            />
          )}
          <div
            className="line-height-100"
            style={{ width: "auto", padding: "4.5% 0" }}
          >
            <Link
              className="gothamLight font14 nav-bar-spacing"
              to={submenu.url}
              offset={0}
              onClick={() => toggleDropDownOpen(toggleId, false)}
            >
              {submenu.title}
            </Link>
          </div>
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 17%;
  height: auto;
  position: fixed;
  top: ${(props) => (props.y < 100 ? "10px" : "0px")};
  padding: 1%;
  margin-top: 50px;
  border-radius: 0 0 1rem 1rem;
  left: ${(props) => (props.DropDownOpen ? "21%" : "-200%")};
  z-index: 9999;
  @media (max-width: 915px) {
    display: none;
  }
`;
