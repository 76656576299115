import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { whiteBg } from "../style/colors";
import { textLeft } from "../style/aligns";
import { getContent } from "../constants/languages/languages";
import styled from "styled-components";
import metadata from "../constants/metadata";

import InfoImage from "../components/Screens/Solutions/InfoImage";
import TextButton from "../components/Buttons/TextButton";
import Img1 from "./../assets/WeAreLambda/icon1.webp";
import SEO from "../components/SEO";

function NotFound(props) {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);
  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);

  const banners = [
    {
      id: 1,
      background: whiteBg,
      txtAlign: textLeft,
      title: content.notFound.title,
      description: (
        <div>
          <br />
          <p className={"gothamLight font-text blackColor"}>
            {content.notFound.content[0].beforeLambda}{" "}
            <span>
              <TextButton
                title="LAMBDA"
                to="/"
                className="pirulen font-text secondaryColor"
              />
            </span>
            {content.notFound.content[0].afterLambda}
          </p>

          <br />
          <br />
          <p className={"gothamLight font-text blackColor"}>
            {content.notFound.content[1]}
          </p>
        </div>
      ),
      rightImg: true,
      img: Img1,
      titleClass: `gothamBold font-title primaryColor ${textLeft}`,
    },
  ];

  return (
    <Wrapper id={"initial"}>
      <SEO metadata={metadata.notFound} />
      {banners.map((elem) => {
        return (
          <InfoImage
            key={elem.id}
            id={elem.id}
            background={elem.background}
            txtAlign={elem.txtAlign}
            title={elem.title}
            description={elem.description}
            rightImg={elem.rightImg}
            img={elem.img}
            titleClass={elem.titleClass}
            imgPadding={{ padding: "10%" }}
          />
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 120px 0 5% 0;
`;

export default NotFound;
