import React from "react";
import { selectLanguage } from "./constants/languages/languages.js";

// Screens
import Landing from "./screens/Landing.jsx";

// REDUX
import store from "./app/store";
import { Provider } from "react-redux";

export default function App() {
  const currentLanguage = localStorage.getItem("language");
  if (!currentLanguage) {
    const navigatorLanguages = navigator.languages;
    const language = selectLanguage(navigatorLanguages);
    localStorage.setItem("language", language);
  }
  return (
    <Provider store={store}>
      <Landing />
    </Provider>
  );
}
