import React from "react";
import { InlineWidget } from "react-calendly";
import "react-intl-tel-input/dist/main.css";
import styled from "styled-components";
import CloseIcon from "../../assets/svg/CloseIcon";

export default function Calendly({ calendlyOpen, toggleCalendly }) {
  return (
    <Wrapper className="animate flex flexColumn" calendlyOpen={calendlyOpen}>
      <div className="flex flexColumn align-items-end">
        <CloseBtn
          onClick={() => toggleCalendly(!calendlyOpen)}
          className="animate pointer"
          style={{
            padding: "0px",
            color: "black",
          }}
        >
          <CloseIcon />
        </CloseBtn>
      </div>
      <InlineWidget
        url="https://calendly.com/luis_lambda/intro_to_lambda"
        styles={{
          minWidth: "320px",
          height: "700px",
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 60%;
  height: 700px;
  position: fixed;
  opacity: 1;
  top: 15%;
  padding: 0;
  left: ${(props) => (props.calendlyOpen ? "20%" : "-200%")};
  z-index: 9999;
  @media (max-width: 859px) {
    width: 96%;
    height: auto;
    top: 3%;
    left: ${(props) => (props.calendlyOpen ? "2%" : "-200%")};
  }
`;

const CloseBtn = styled.button`
  position: relative;
  top: 3vw;
  left: -7vw;
  z-index: 2;
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 0px;
  @media only screen and (max-width: 1600px) {
    position: static;
    margin-bottom: 10px;
  }
`;
