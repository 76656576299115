import styled from "styled-components";
import Solutions from "../../components/Screens/Solutions/Solutions";
import SEO from "../../components/SEO";
import metadata from "../../constants/metadata";
import { useLayoutEffect, useEffect } from "react";

// Facebook Pixel
import { viewContentEvent } from "../../constants/facebookPixelEvent";

function SolutionsScreen(props) {
  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);
  useEffect(() => {
    viewContentEvent();
  }, []);
  return (
    <>
      <SEO metadata={metadata.solutions} />
      <Wrapper
        id="initial"
        className="flex flexRow flexCenter solScreenPadding"
      >
        <Solutions />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

export default SolutionsScreen;
