import { useSelector } from "react-redux";
import { getContent } from "../constants/languages/languages";

// STYLED
import styled from "styled-components";

// COMPONENTS
import Info from "../components/Screens/Solutions/Info";
import SEO from "../components/SEO";
import metadata from "../constants/metadata";
import { useLayoutEffect } from "react";

// COLORS
import { gradientBg, lightBg, whiteBg } from "../style/colors";

// ALIGNS
import { textCenter, textLeft } from "../style/aligns";

// CONSTANTS
const descriptionClass = "gothamLight font-text textJustify";

function PrivacyPolicy(props) {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);
  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);

  const infoBanners = [
    {
      background: gradientBg,
      title: content.privacyPolicy.title,
      titleClass: "gothamBold font-title whiteColor",
      description: content.privacyPolicy.description,
      descriptionClass: `${descriptionClass} whiteColor`,
      align: textCenter,
      isNotP: false,
    },
    {
      background: whiteBg,
      title: content.privacyPolicy.sections[0].title,
      titleClass: "gothamBold font-title primaryColor",
      description: content.privacyPolicy.sections[0].description[0],
      descriptionClass: `${descriptionClass} primaryColor`,
      align: textLeft,
      isNotP: false,
    },
    {
      background: lightBg,
      title: content.privacyPolicy.sections[1].title,
      titleClass: "gothamBold font-title primaryColor",
      description: (
        <div>
          <p className={descriptionClass}>
            {content.privacyPolicy.sections[1].description[0]}
          </p>
          <br />
          <p className={descriptionClass}>
            {content.privacyPolicy.sections[1].description[1]}
          </p>
        </div>
      ),
      descriptionClass: descriptionClass,
      align: textLeft,
      isNotP: true,
    },
    {
      background: whiteBg,
      title: content.privacyPolicy.sections[2].title,
      titleClass: "gothamBold font-title primaryColor",
      description: (
        <div>
          <p className={descriptionClass}>
            {content.privacyPolicy.sections[2].description[0]}
          </p>
          <br />
          <p className={descriptionClass}>
            {content.privacyPolicy.sections[2].description[1]}
          </p>
        </div>
      ),
      descriptionClass: descriptionClass,
      align: textLeft,
      isNotP: true,
    },
    {
      background: lightBg,
      title: content.privacyPolicy.sections[3].title,
      titleClass: "gothamBold font-title primaryColor",
      description: content.privacyPolicy.sections[3].description[0],
      descriptionClass: `${descriptionClass} primaryColor`,
      align: textLeft,
      isNotP: false,
    },
    {
      background: whiteBg,
      title: content.privacyPolicy.sections[4].title,
      titleClass: "gothamBold font-title primaryColor",
      description: (
        <div>
          <p className={descriptionClass}>
            {content.privacyPolicy.sections[4].description[0]}
          </p>
          <br />
          <p className={descriptionClass}>
            {content.privacyPolicy.sections[4].description[1]}
          </p>
          <br />
          <p className={descriptionClass}>
            {content.privacyPolicy.sections[4].description[2]}
          </p>
        </div>
      ),
      descriptionClass: descriptionClass,
      align: textLeft,
      isNotP: true,
    },
  ];

  return (
    <Wrapper id="initial">
      <SEO metadata={metadata.privacyPolicy} />
      {infoBanners.map((elem, index) => {
        return (
          <Info
            key={index}
            background={elem.background}
            title={elem.title}
            titleClass={elem.titleClass}
            description={elem.description}
            descriptionClass={elem.descriptionClass}
            align={elem.align}
            padding={"2% 15%"}
            isNotP={elem.isNotP}
          />
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0 5% 0;
`;

export default PrivacyPolicy;
