import styled from "styled-components";
import CloseIcon from "../../assets/svg/CloseIcon";

function ContactBanner(props) {
  const id = props.id;
  const background = props.background;
  const txtAlign = props.txtAlign;
  const title = props.title;
  const description = props.description;
  const rightImg = props.rightImg;
  const img = props.img;

  const titleClass = props.titleClass;
  const padding = props.padding;
  const imgPadding = props.imgPadding;
  const toggleContact = props.toggleContact;
  const contactOpen = props.contactOpen;

  return (
    <div
      id={id}
      className={background}
      style={
        padding
          ? { padding: padding, margin: "0px", width: "100%" }
          : { padding: "2% 15% 2% 15%", margin: "0px", width: "100%" }
      }
    >
      <div
        className="flex flexRow flexSpaceNull align-items-start"
        style={{ alignItems: "end", alignContent: "end" }}
      >
        <p className="pirulen nav-bar-spacing font-subtitle whiteColor">
          LAMBDA
        </p>
        <CloseBtn
          onClick={() => toggleContact(!contactOpen)}
          className="animate pointer"
          style={{
            padding: "0px",
            minHeight: "50%",
            color: "black",
          }}
        >
          <CloseIcon />
        </CloseBtn>
      </div>
      <br />
      <div className="row">
        {!rightImg && (
          <div
            className="col-lg d-flex justify-content-center"
            style={{ padding: "0px", margin: "0px" }}
          >
            <ImgStyle
              title={title}
              className="imgDisappear"
              src={img}
              alt={title}
              width="auto"
              height="auto"
              style={
                imgPadding ? imgPadding : { padding: "5%", maxWidth: "100%" }
              }
            />
          </div>
        )}
        <div className="col-lg">
          <h1
            className={
              titleClass
                ? titleClass
                : `gothamBold font45 primaryColor ${txtAlign}`
            }
            style={{ margin: "0px" }}
          >
            <b>{title}</b>
          </h1>
          <br />
          <div className={txtAlign}>{description}</div>
        </div>
        {rightImg && (
          <div
            className="col-lg d-flex justify-content-center"
            style={{ padding: "0px", margin: "0px" }}
          >
            <ImgStyle
              title={title}
              className="imgDisappear"
              src={img}
              alt={title}
              width="auto"
              height="auto"
              style={
                imgPadding ? imgPadding : { padding: "5%", maxWidth: "100%" }
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

const ImgStyle = styled.img`
  padding: 0%;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

export default ContactBanner;
