import { useState } from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../../constants/languages/languages";
import styled from "styled-components";

import Image from "../../../assets/main.webp";
import CustomizableButton from "./../../Buttons/CustomizableButton";
import Backdrop from "../../Elements/Backdrop";

/* import { paddingStyle } from "../../../style/Responsive/paddingStyle"; */
import { secondaryColor, alternativeColor } from "../../../style/colors";
import Calendly from "../../Modal/Calendly";

function Title(props) {
  const language = useSelector((state) => state.language.language);
  const [calendlyOpen, toggleCalendly] = useState(false);
  const content = getContent(language);

  return (
    <Wrapper id="initial" className="flex flexRow flexCenter gradientBg">
      {/*-------------------------------- MODALS --------------------------------*/}
      <Calendly calendlyOpen={calendlyOpen} toggleCalendly={toggleCalendly} />
      {calendlyOpen && <Backdrop toggle={toggleCalendly} />}
      {/*-----------------------------------------------------------------------*/}

      <div className="" style={{ padding: "5% 13% 1% 13%" }}>
        <Advertising className="flexSpaceCenter">
          <AddImage>
            <AddImageInner>
              <ImgWrapper className="flexCenter">
                <img
                  title=""
                  width="1497px"
                  height="1051px"
                  src={Image}
                  alt=""
                />
              </ImgWrapper>
            </AddImageInner>
          </AddImage>
          <AddText>
            <TitleWrapper className="mobile-section-vertical-padding">
              <h1 className="gothamBlackRegular uwd-pre-wrap font-home-title whiteColor textRight">
                {content.home.title.title}
              </h1>
            </TitleWrapper>
            <div className="br" />
            <div className="flex flexColumn flexSpaceNull alignRight">
              <div className="flex flexRow" style={{ margin: "2% 0px 5% 0px" }}>
                <p className="textRight uwd-pre-wrap">
                  <span className="pirulen font30 whiteColor">LAMBDA</span>
                  <span className="gothamLight font30 whiteColor">
                    ,{" "}
                    <span
                      style={{ borderBottom: `2px solid ${secondaryColor}` }}
                    >
                      {content.home.title.description.dataScience}
                    </span>{" "}
                    {content.home.title.description.content}
                  </span>
                </p>
              </div>
              <br />
            </div>
            <div className="flex flexColumn flexSpaceNull alignRight">
              <CustomizableButton
                id={0}
                font={"gothamMedium font15 whiteColor"}
                title={content.home.title.button}
                isCallback={true}
                callBack={(id) => toggleCalendly(!calendlyOpen)}
                background={alternativeColor}
                border={`5px solid ${alternativeColor}`}
                padding={"1% 5%"}
                hover={{
                  border: `5px solid ${alternativeColor}`,
                  background: alternativeColor,
                }}
              />
            </div>
          </AddText>
        </Advertising>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const TitleWrapper = styled.section`
  width: 100%;
  position: relative;

  padding: 0px 0px 0px 0px;
`;
const Advertising = styled.div`
  margin: 50px 0 0 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddImage = styled.div`
  position: relative;
  width: 100%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddText = styled.div`
  width: 70%;
  padding: 0px 0px 0px 0px;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddImageInner = styled.div`
  width: 100%;
  position: relative;
  top: -100%;
  left: 0;
  @media (max-width: 1190px) {
    top: -100%;
  }
  @media (max-width: 920px) {
    top: -100%;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -100%;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0px;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 1000px) {
    padding: 0;
  }
`;

export default Title;
