import { configureStore } from "@reduxjs/toolkit";

// SLICES
import blogsReducer from "./slices/blogsSlice";
import blogInfoReducer from "./slices/blogInfoSlice";
import languageReducer from "./slices/languageSlice";

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    language: languageReducer,
    blogs: blogsReducer,
    blogInfo: blogInfoReducer,
  },
});
