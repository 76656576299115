import useWindowDimensions from "../../../hooks/useWindowDimensions";

function InfoImage(props) {
  const { width } = useWindowDimensions();
  const mobileWidth = 780;
  const isMobile = width < mobileWidth;

  const id = props.id;
  const background = props.background;
  const txtAlign = props.txtAlign;
  const title = props.title;
  const description = props.description;
  const rightImg = isMobile ? true : props.rightImg;
  const img = props.img;

  const titleClass = props.titleClass;
  const padding = props.padding;
  const imgPadding = props.imgPadding;

  return (
    <div
      id={id}
      className={`${background} mobile-section-vertical-padding mobile-section-horizontal-padding`}
      style={
        padding
          ? { padding: padding, margin: "0px", width: "100%" }
          : { padding: "3% 15% 2% 15% ", margin: "0px", width: "100%" }
      }
    >
      <div className="row">
        {!rightImg && (
          <div
            className="col-lg d-flex justify-content-center"
            style={{ padding: "0px", margin: "0px" }}
          >
            <img
              title={title}
              loading="lazy"
              src={img}
              alt={title}
              width="auto"
              height="auto"
              style={
                imgPadding ? imgPadding : { padding: "5%", maxWidth: "100%" }
              }
            />
          </div>
        )}
        <div className="col-lg">
          <p
            className={
              titleClass
                ? titleClass
                : `gothamBold font50 primaryColor ${txtAlign}`
            }
            style={{ margin: "0px" }}
          >
            <b>{title}</b>
          </p>
          <br />
          <div className={txtAlign}>{description}</div>
        </div>
        {rightImg && (
          <div
            className="col-lg d-flex justify-content-center"
            style={{ padding: "0px", margin: "0px" }}
          >
            <img
              title={title}
              loading="lazy"
              src={img}
              alt={title}
              width="auto"
              height="auto"
              style={
                imgPadding ? imgPadding : { padding: "5%", maxWidth: "100%" }
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoImage;
