import spanish from "./content/spanish";
import english from "./content/english";

const languages = {
  en: "English",
  es: "Español",
  /*fr: "Français",
  it: "Italiano",
  de: "Deutsch",
  pt: "Português",
  ru: "Русский",
  nl: "Nederlands",*/
};

const content = {
  en: english,
  es: spanish,
  /*fr: spanish,
  it: spanish,
  de: spanish,
  pt: spanish,
  ru: spanish,
  nl: spanish,*/
};

export const getContent = (language) => {
  if (!language || !content.hasOwnProperty(language)) return content.en;
  return content[language];
};

export const selectLanguage = (navigatorLanguages) => {
  if (navigatorLanguages) {
    for (const navigatorLanguage of navigatorLanguages) {
      for (const language in languages) {
        if (navigatorLanguage.startsWith(language)) {
          return language;
        }
      }
    }
  }
  return Object.keys(languages)[0];
};

export default languages;
