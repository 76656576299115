import * as STATUS_CODES from "../statusCodes";
import { getContent } from "../../constants/languages/languages";

export function postBlogHandler(status) {
  const language = localStorage.getItem("language") || "en";
  const content = getContent(language);

  const result = {
    OK: false,
    message: content.blogs.post.error,
  };

  switch (status) {
    case STATUS_CODES.CREATED:
      result.OK = true;
      result.message = content.blogs.post.created;
      break;
    case STATUS_CODES.BAD_REQUEST:
      result.message = content.blogs.post.badRequest;
      break;
    default:
      break;
  }
  return result;
}
