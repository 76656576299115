import { getContent } from "./languages/languages";

const url = "https://x8ki-letl-twmt.n7.xano.io/api:z89WqTV3/users";
const method = "POST";

export const sendContactInfo = (
  dataSentOpen,
  toggleDataSent,
  dataSentErrorOpen,
  toggleDataSentError,
  body
) => {
  fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => toggleDataSent(!dataSentOpen))
    .catch((error) => {
      toggleDataSentError(!dataSentErrorOpen);
    });
};

export const formatBody = (
  names,
  lastnames,
  company,
  email,
  country,
  dialcode,
  phone,
  typeinfo,
  message,
  area
) => {
  const language = localStorage.getItem("language");
  const content = getContent(language);
  return {
    names: names,
    lastnames: lastnames,
    company: company,
    email: email,
    country: country,
    dialcode: dialcode,
    phone: phone,
    typeinfo: content.contact.moreInfoOptions[typeinfo],
    message: message,
    area: content.contact.areaOptions[area],
  };
};
