import { paddingStyle } from "../../../style/Responsive/paddingStyle";

function Info(props) {
  const background = props.background;
  const title = props.title;
  const titleClass = props.titleClass;
  const description = props.description;
  const descriptionClass = props.descriptionClass;
  const align = props.align;

  const padding = props.padding;
  const isNotP = props.isNotP;

  return (
    <div
      className={`${background} mobile-section-vertical-padding mobile-section-horizontal-padding`}
      style={padding ? { padding: padding } : paddingStyle}
    >
      {title && <h2 className={`${titleClass} ${align}`}>{title}</h2>}
      {title && <br />}
      {!isNotP && (
        <p className={`${descriptionClass} ${align}`}>{description}</p>
      )}
      {isNotP && <div className={align}>{description}</div>}
    </div>
  );
}

export default Info;
