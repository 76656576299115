import ContentRender from "./ContentRender";

function BlogContent({ content }) {
  return (
    <div style={{ width: "100%" }}>
      <div
        className="blog-content gothamLight font-text"
        style={{ width: "100%" }}
      >
        <ContentRender content={content} />
      </div>
    </div>
  );
}

export default BlogContent;
