import styled from "styled-components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const textClass = "gothamLight font-text blackColor textCenter";
const layoutStyle = { padding: "0% 12.5%" };

function addIcon(someLayout, icon, index) {
  someLayout.push(
    <div key={index} className="col">
      <div className="col-md d-flex justify-content-center">
        <IconStyle
          title={`icon${index}`}
          loading="lazy"
          src={icon}
          alt={`icon${index}`}
          width="auto"
          height="auto"
          style={{ maxWidth: "100%" }}
        />
      </div>
    </div>
  );
}

function addText(someLayout, text, index) {
  someLayout.push(
    <div key={index} className="col-md">
      <p className={textClass}>{text}</p>
    </div>
  );
}

function getLayouts(iconUp, icons, texts) {
  const size = icons.length;
  const upperLayout = [];
  const bottomLayout = [];
  for (let i = 0; i < size; i++) {
    if (iconUp) {
      if (i % 2 === 0) {
        addIcon(upperLayout, icons[i], i);
        addText(bottomLayout, texts[i], i);
      } else {
        addText(upperLayout, texts[i], i);
        addIcon(bottomLayout, icons[i], i);
      }
    } else {
      if (i % 2 === 0) {
        addText(upperLayout, texts[i], i);
        addIcon(bottomLayout, icons[i], i);
      } else {
        addIcon(upperLayout, icons[i], i);
        addText(bottomLayout, texts[i], i);
      }
    }
  }
  return [upperLayout, bottomLayout];
}

function getMobileLayout(icons, texts) {
  const mobileLayout = [];
  for (let i = 0; i < icons.length; i++) {
    mobileLayout.push(
      <div key={i} className="col-xl">
        <p className={textClass}>{texts[i]}</p>
        <br />
        <div className="d-flex justify-content-center">
          <IconStyle
            title={`icon${i}`}
            loading="lazy"
            src={icons[i]}
            alt={`icon${i}`}
            width="auto"
            height="auto"
            style={{ maxWidth: "70px" }}
          />
        </div>
        <br />
      </div>
    );
  }
  return mobileLayout;
}

function TimeLine(props) {
  const { width } = useWindowDimensions();
  const mobileWidth = 780;

  const img = props.img;
  const iconUp = props.iconUp;
  const icons = props.icons;
  const texts = props.texts;
  const [upperLayout, bottomLayout] = getLayouts(iconUp, icons, texts);
  const mobileLayout = getMobileLayout(icons, texts);

  if (width < mobileWidth)
    return (
      <div style={{ padding: "4% 15% 0% 15%" }}>
        <div className="flex flexColumn flexSpaceAround">
          <div className="col-md d-flex justify-content-center">
            <ImgStyle
              title="timeline"
              loading="lazy"
              src={img}
              alt="timeline"
              width="auto"
              height="auto"
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="row" style={layoutStyle}>
            {mobileLayout}
          </div>
        </div>
      </div>
    );

  return (
    <div style={{ padding: "4% 15% 0% 15%" }}>
      <div className="flex flexColumn flexSpaceAround">
        <div className="row d-flex align-items-end" style={layoutStyle}>
          {upperLayout}
        </div>
        <div className="col-md d-flex justify-content-center">
          <ImgStyle
            title="timeline"
            loading="lazy"
            src={img}
            alt="timeline"
            width="auto"
            height="auto"
            style={{ maxWidth: "100%" }}
          />
        </div>
        <div className="row" style={layoutStyle}>
          {bottomLayout}
        </div>
      </div>
    </div>
  );
}

const ImgStyle = styled.img`
  padding: 5px 0;
  width: 80%;
  top: 5%;
`;
const IconStyle = styled.img`
  padding: 0%;
  width: 40%;
`;

export default TimeLine;
