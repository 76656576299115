export const primaryColor = "#133957";
export const secondaryColor = "#2d96a7";
export const alternativeColor = "#ffb530";

export const buttonBgColor = "#dbedef";

export const whiteBg = "whiteBg";
export const lightBg = "lightBg";
export const gradientBg = "gradientBg";

export const initial = "gray";
export const success = "green";
export const error = "red";
