import { HTTP_METHODS } from "../config";
import { blogSectionURL } from "../request";

// [method, sectionURL, route]
export const getBlogs = () => {
  const language = localStorage.getItem("language") || "en";
  return [HTTP_METHODS.GET, blogSectionURL, `/${language}`];
};

export const getContentOf = (url) => {
  const language = localStorage.getItem("language") || "en";
  return [HTTP_METHODS.GET, blogSectionURL, `/${language}/${url}`];
};

export const postBlog = (url) => {
  const language = localStorage.getItem("language") || "en";
  return [HTTP_METHODS.POST, blogSectionURL, `/${language}/${url}`];
};
