import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function ShowSolution(props) {
  const { width } = useWindowDimensions();
  const mobileWidth = 780;
  const isMobile = width < mobileWidth;

  const icon = props.icon;
  const img = props.img;
  const title = props.title.toUpperCase();
  const rightImg = props.rightImg;

  return (
    <Wrapper>
      <div
        className="flex flexRow flexCenter whiteBg"
        style={{ padding: "0px" }}
      >
        <div className="" style={{ padding: "0px", margin: "0px" }}>
          <div className="row">
            {!rightImg && (
              <div className="col-lg imgDisappear">
                <ImgWrapper className="flexCenter">
                  <img
                    title={title}
                    loading="lazy"
                    src={img}
                    alt={title}
                    width="auto"
                    height="auto"
                  />
                </ImgWrapper>
                {isMobile && <br />}
              </div>
            )}
            <div className="col-lg">
              <div className="d-flex">
                {!isMobile && (
                  <div
                    className="col-sm"
                    style={{ padding: "0px", maxWidth: "90px" }}
                  >
                    <IconWrapper
                      className="flexCenter"
                      style={{ padding: "0px" }}
                    >
                      <img
                        title="icon"
                        loading="lazy"
                        src={icon}
                        alt="icon"
                        width="auto"
                        height="auto"
                        style={{
                          width: "auto",
                          maxHeight: "70px",
                        }}
                      />
                    </IconWrapper>
                  </div>
                )}
                <div className="col-sm">
                  <div className="d-flex">
                    {isMobile && (
                      <div style={{ padding: "0 15px 0 0" }}>
                        <IconWrapper
                          className="mobile-solution-icon-size"
                          style={{ padding: "0px" }}
                        >
                          <img
                            title="icon"
                            loading="lazy"
                            width="45px"
                            height="auto"
                            src={icon}
                            alt="icon"
                          />
                        </IconWrapper>
                      </div>
                    )}
                    <div>
                      <p className="gothamBold font-title line-height-100 secondaryColor">
                        {title}
                      </p>
                      {isMobile && (
                        <div
                          className="radius8 alternativeBg"
                          style={{
                            width: "25%",
                            height: "5px",
                            margin: "15px 0",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {!isMobile && (
                    <div
                      className="radius8 alternativeBg"
                      style={{
                        width: "25%",
                        height: "5px",
                        margin: "15px 0",
                      }}
                    />
                  )}
                  {props.children}
                </div>
              </div>
            </div>
            {rightImg && (
              <div className="col-lg">
                <ImgWrapper className="flexCenter imgDisappear">
                  <img
                    title={title}
                    loading="lazy"
                    src={img}
                    alt={title}
                    width="auto"
                    height="auto"
                  />
                </ImgWrapper>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ImgWrapper = styled.div`
  width: 90%;
  padding: 0px;
  img {
    width: 90%;
    height: auto;
  }
  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const IconWrapper = styled.div`
  width: 90%;
  padding: 0;
  @media (max-width: 200px) {
    padding: 0;
  }
`;

export default ShowSolution;
