import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setLanguage } from "../../app/slices/languageSlice";
import { setBlogs } from "../../app/slices/blogsSlice";
import { setBlogInfo } from "../../app/slices/blogInfoSlice";
import { getContent } from "../../constants/languages/languages";
import styled from "styled-components";

import TextButton from "../Buttons/TextButton";
import CustomizableButton from "../Buttons/CustomizableButton";
import { secondaryColor, alternativeColor } from "../../style/colors";

// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import languages from "../../constants/languages/languages";

const Select = styled.select`
  border: none;
  color: white;
  background: none;
  padding: 1%;
  width: 130px;
  font-family: "Gotham-Light";
`;

export default function Sidebar({
  sidebarOpen,
  toggleSidebar,
  contactOpen,
  toggleContact,
}) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  const solutionItems = [
    {
      title: content.home.solutions.buttons[3],
      url: "/soluciones/soluciones_personalizadas",
    },
    {
      title: content.home.solutions.buttons[0],
      url: "/soluciones/planeacion_de_la_produccion",
    },
    {
      title: content.home.solutions.buttons[1],
      url: "/soluciones/prediccion_de_renuncias",
    },
    {
      title: content.home.solutions.buttons[2],
      url: "/soluciones/clientes_potenciales",
    },
  ];

  return (
    <Wrapper
      className="animate primaryBg"
      sidebarOpen={sidebarOpen}
      style={
        sidebarOpen
          ? {
              minWidth: "22%",
              overflow: "scroll",
              overflowX: "hidden",
            }
          : {
              overflow: "scroll",
              overflowX: "hidden",
            }
      }
    >
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <TextButton
            title="LAMBDA"
            to="/"
            toggle={{ func: toggleSidebar, value: sidebarOpen }}
            className="pirulen font-subtitle nav-bar-spacing whiteColor"
          />
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
          style={{ padding: "0px", minHeight: "50%" }}
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle
        className="flexColumn flexSpaceCenter"
        style={{ height: "60vh" }}
      >
        <div className="gothamLight font-text nav-bar-spacing whiteColor">
          <p style={{ padding: "10px 15px" }} offset={-80}>
            {content.navBar.options[0]}
          </p>
        </div>
        <div className="dropdown-sidebar">
          <ul>
            {solutionItems.map((item, index) => (
              <li key={index}>
                <div className="gothamLight nav-bar-spacing font15 pointer whiteColor textLeft">
                  <NavLink
                    style={{ padding: "10px 0" }}
                    to={item.url}
                    offset={0}
                    onClick={() => toggleSidebar(!sidebarOpen)}
                  >
                    {item.title}
                  </NavLink>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div
          className="semiBold font15 whiteColor"
          style={{ margin: "20px 25px" }}
        >
          {""}
        </div>
        <div className="gothamLight font-text nav-bar-spacing pointer whiteColor">
          <NavLink
            style={{ padding: "10px 15px" }}
            to="quienes_somos"
            onClick={() => toggleSidebar(!sidebarOpen)}
            offset={-80}
          >
            {content.navBar.options[1]}
          </NavLink>
        </div>
        <div
          className="semiBold font15 whiteColor"
          style={{ margin: "30px 25px" }}
        >
          {""}
        </div>
        <div className="gothamLight font-text nav-bar-spacing pointer whiteColor">
          <NavLink
            style={{ padding: "10px 15px" }}
            to="blog"
            onClick={() => toggleSidebar(!sidebarOpen)}
            offset={-80}
          >
            {content.navBar.options[2]}
          </NavLink>
        </div>
        <div
          className="semiBold font15 whiteColor"
          style={{ margin: "30px 25px" }}
        >
          {""}
        </div>
        <div className="gothamLight font-text nav-bar-spacing pointer whiteColor">
          <a href="https://landing-sight.scilambda.net/">
            {content.navBar.options[3]}
          </a>
        </div>
        <div
          className="semiBold font15 whiteColor"
          style={{ margin: "30px 25px" }}
        >
          {""}
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            width: "100%",
            margin: "none",
            padding: "none",
          }}
        >
          <CustomizableButton
            id={0}
            font={"gothamLight font-text nav-bar-spacing whiteColor"}
            title={content.navBar.contact}
            isCallback={true}
            callBack={(id) => {
              toggleSidebar(!sidebarOpen);
              toggleContact(!contactOpen);
            }}
            width={"100%"}
            background={secondaryColor}
            border={`8px solid ${secondaryColor}`}
            hover={{
              border: `8px solid ${alternativeColor}`,
              background: alternativeColor,
            }}
          />
        </div>
        <div
          className="semiBold font15 whiteColor"
          style={{ margin: "30px 25px" }}
        >
          {""}
        </div>
        <Select
          className="font18"
          value={language}
          onChange={(event) => {
            localStorage.setItem("language", event.target.value);
            dispatch(setLanguage(event.target.value));
            dispatch(setBlogs(null));
            dispatch(setBlogInfo(null));
          }}
        >
          {Object.keys(languages).map((languageCode) => (
            <option key={languageCode} value={languageCode}>
              {languages[languageCode]}
            </option>
          ))}
        </Select>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
