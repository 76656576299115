import { useNavigate } from "react-router-dom";

const style = { padding: "0px", border: "none", background: "none" };

function TextButton(props) {
  const navigate = useNavigate();

  const title = props.title;
  const className = props.className;
  const to = props.to;

  // OPTIONAL
  const toggle = props.toggle;

  const onClickFunction = (event) => {
    event.preventDefault();
    if (toggle) toggle.func(!toggle.value);
    navigate(to, { replace: true });
  };

  return (
    <button className={className} style={style} onClick={onClickFunction}>
      {title}
    </button>
  );
}

export default TextButton;
