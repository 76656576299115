// Regular expressions
export const nameRegEx = /^[\p{L}\s]+[\p{L}]$/iu;
export const emailRegEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const phoneRegEx = /[0-9]{1,}$/;

export function testNames(name) {
  return nameRegEx.test(name);
}

export function testEmail(email) {
  return emailRegEx.test(email);
}

export function testPhone(number) {
  if (number === -1) return false;
  return phoneRegEx.test(number);
}

export function testNonEmpty(input) {
  return input === -1 ? -1 : input.length !== 0;
}

export function getValidStatus(input) {
  if (testNonEmpty(input) === -1) {
    return "gray";
  }
  if (testNames(input)) {
    return "green";
  }
  return "red";
}

export function getEmailValidStatus(input) {
  if (input === -1) return "gray";
  return testEmail(input) ? "green" : "red";
}

export function getPhoneStatus(input) {
  if (input === -1) return "gray";
  return testPhone(input) ? "green" : "red";
}

export function getAreaStatus(input) {
  if (input === -1) return "gray";
  if (input.includes("Sector")) return "red";
  if (input === "") return "red";
  return "green";
}

export function getInformationStatus(input) {
  if (input === -1) return "gray";
  if (input.includes("Quiero")) return "red";
  if (input === "") return "red";
  return "green";
}
