import { createSlice } from "@reduxjs/toolkit";

export const blogsSlice = createSlice({
  name: "blogs",
  initialState: {
    list: null,
  },
  reducers: {
    setBlogs: (state, action) => {
      state.list = action.payload;
    },
  },
});

// Action creators are generated for each case of reducer function
export const { setBlogs } = blogsSlice.actions;

export default blogsSlice.reducer;
