import CustomizableButton from "../../Buttons/CustomizableButton";
import { paddingStyle } from "../../../style/Responsive/paddingStyle";
import { alternativeColor } from "../../../style/colors";

function Solution(props) {
  const title = props.title;
  const font = props.font;
  const to = props.to;
  const background = props.background;
  const border = props.border;
  const replace = props.replace;

  const padding = props.padding;
  const innerPadding = props.innerPadding;
  const callBack = props.callBack;

  return (
    <div
      className="flex flexColumn flexSpaceCenter whiteBg"
      style={padding ? padding : paddingStyle}
    >
      <CustomizableButton
        key={0}
        id={0}
        font={`${font} mobile-button-padding`}
        title={title.toUpperCase()}
        isCallback={callBack ? true : false}
        callBack={callBack}
        to={to}
        replace={replace}
        background={background}
        border={border}
        padding={innerPadding}
        hover={{ border: "none", background: alternativeColor }}
      />
    </div>
  );
}

export default Solution;
