import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../app/slices/languageSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { secondaryColor, alternativeColor } from "../../style/colors";
import { setBlogs } from "../../app/slices/blogsSlice";
import { setBlogInfo } from "../../app/slices/blogInfoSlice";
import { getContent } from "../../constants/languages/languages";
import metadata from "../../constants/metadata";
import styled from "styled-components";

// Components
import Sidebar from "../Nav/Sidebar";
import Contact from "../Modal/Contact";
import DataSent from "../Modal/DataSent";
import DataSentError from "../Modal/DataSentError";
import Backdrop from "../Elements/Backdrop";
import TextButton from "../Buttons/TextButton";
import CustomizableButton from "../Buttons/CustomizableButton";
import Dropdown from "./DropDown Menu/Dropdown";
import SEO from "../SEO";

// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";
import languages from "../../constants/languages/languages";

const Select = styled.select`
  border: none;
  color: white;
  background: none;
  padding: 1%;
  width: 130px;
  font-family: "Gotham-Light";
`;

function displayOption(key, navTo, title) {
  return (
    <div
      key={key}
      className="gothamLight nav-bar-spacing font15 pointer whiteColor textCenter"
    >
      <NavLink style={{ padding: "10px 0" }} to={navTo} offset={0}>
        {title.toUpperCase()}
      </NavLink>
    </div>
  );
}

function displaySeparator(key) {
  return (
    <div
      key={key}
      className="gothamThin font14 whiteColor"
      style={{ padding: "1%" }}
    >
      |
    </div>
  );
}

export default function TopNavbar({ instantPopUp }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [contactOpen, toggleContact] = useState(instantPopUp);
  const [dataSentOpen, toggleDataSent] = useState(false);
  const [dataSentErrorOpen, toggleDataSentError] = useState(false);

  const [showSolutions, setShowSolutions] = useState(false);

  const toggleCount = [false, false];
  const every = (arr) => arr.every((v) => v === false);
  const some = (arr) => arr.some((v) => v === true);

  const toggleShowSolutions = (id, val) => {
    toggleCount[id] = val;
    if (every(toggleCount)) setShowSolutions(false);
    if (some(toggleCount)) setShowSolutions(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  useEffect(() => {
    document.body.style.overflow = contactOpen ? "hidden" : "auto";
    const path = window.location.pathname.replace(/\//g, "");
    if (!contactOpen && path === "contacto") {
      navigate("/", { replace: true });
    }
  }, [contactOpen, navigate]);

  const solutionItems = [
    {
      title: content.home.solutions.buttons[3],
      url: "/soluciones/soluciones_personalizadas",
    },
    {
      title: content.home.solutions.buttons[0],
      url: "/soluciones/planeacion_de_la_produccion",
    },
    {
      title: content.home.solutions.buttons[1],
      url: "/soluciones/prediccion_de_renuncias",
    },
    {
      title: content.home.solutions.buttons[2],
      url: "/soluciones/clientes_potenciales",
    },
  ];

  return (
    <>
      {instantPopUp && <SEO metadata={metadata.contact} />}
      {/* SideBar Modal*/}
      <Sidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        contactOpen={contactOpen}
        toggleContact={toggleContact}
      />
      {sidebarOpen && <Backdrop toggle={toggleSidebar} />}

      {/* DataSent Modal*/}
      <DataSent dataSentOpen={dataSentOpen} toggleDataSent={toggleDataSent} />
      {dataSentOpen && <Backdrop toggle={toggleDataSent} />}

      {/* DataSentError Modal*/}
      <DataSentError
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataSentError={toggleDataSentError}
      />
      {dataSentErrorOpen && <Backdrop toggle={toggleDataSentError} />}

      {/* Contact Modal*/}
      <Contact
        contactOpen={contactOpen}
        toggleContact={toggleContact}
        dataSentOpen={dataSentOpen}
        toggleDataSent={toggleDataSent}
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataErrorSent={toggleDataSentError}
      />
      {contactOpen && <Backdrop toggle={toggleContact} />}

      {/* Show Solutions Modal*/}
      <Dropdown
        submenus={solutionItems}
        DropDownOpen={showSolutions}
        toggleDropDownOpen={toggleShowSolutions}
        toggleId={0}
      />
      {showSolutions && (
        <Backdrop
          toggle={toggleShowSolutions}
          toggleId={0}
          transparent={true}
        />
      )}

      <Wrapper
        className="animate primaryBg"
        style={
          y > 100
            ? { height: "70px", minHeight: "70px", padding: "0px 150px" }
            : { height: "80px", minHeight: "80px", padding: "0px 150px" }
        }
      >
        <NavInner
          className="flexSpaceCenter"
          style={{ padding: "0px", margin: "0px" }}
        >
          <TextButton
            title="LAMBDA"
            to="/"
            className="pirulen nav-bar-spacing font-subtitle whiteColor"
          />

          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper
            className="flexSpaceCenter whiteColor"
            style={{ minWidth: "600px", width: "80%" }}
          >
            {[
              <div
                id="solutionNavBarButton"
                key={0}
                className="d-flex flex-column align-items-center gothamLight nav-bar-spacing font14 pointer whiteColorN textCenter"
              >
                <CustomizableButton
                  key={0}
                  id={0}
                  font={
                    "gothamLight nav-bar-spacing font15 pointer whiteColorN textCenter"
                  }
                  title={content.navBar.options[0]}
                  isIdle={true}
                  background={"transparent"}
                  border={"none"}
                  padding={"none"}
                  hover={{
                    border: "none",
                    background: "transparent",
                    color: alternativeColor,
                  }}
                  func={toggleShowSolutions}
                  toggleId={1}
                />
              </div>,

              displaySeparator(1),
              displayOption(2, "quienes_somos", content.navBar.options[1]),
              displaySeparator(3),
              displayOption(4, "blog", content.navBar.options[2]),
              displaySeparator(5),
              <div
                key={6}
                className="gothamLight nav-bar-spacing font15 pointer whiteColor textCenter"
              >
                <a href="https://landing-sight.scilambda.net/">
                  {content.navBar.options[3]}
                </a>
              </div>,
            ]}
            <UlWrapperRight>
              <CustomizableButton
                id={0}
                font={"gothamLight font15 whiteColor nav-bar-spacing"}
                title={content.navBar.contact}
                isCallback={true}
                callBack={(id) => {
                  toggleContact(!contactOpen);
                }}
                width={"100%"}
                margin={"5px"}
                padding={"0 5px"}
                background={secondaryColor}
                border={`2px solid ${secondaryColor}`}
                hover={{
                  border: `2px solid ${alternativeColor}`,
                  background: alternativeColor,
                }}
              />
            </UlWrapperRight>
            <Select
              className="font18"
              value={language}
              onChange={(event) => {
                localStorage.setItem("language", event.target.value);
                dispatch(setLanguage(event.target.value));
                dispatch(setBlogs(null));
                dispatch(setBlogInfo(null));
              }}
            >
              {Object.keys(languages).map((languageCode) => (
                <option key={languageCode} value={languageCode}>
                  {languages[languageCode]}
                </option>
              ))}
            </Select>
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  padding: 0 15%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 915px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  width: 52vw;
  @media (max-width: 915px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  width: 20%;
  min-width: 100px;
  @media (max-width: 915px) {
    display: none;
  }
`;
