const fbq = window.fbq;

export const leadEvent = () => {
  fbq("track", "Lead");
};

export const contactEvent = () => {
  fbq("track", "Contact");
};

export const viewContentEvent = () => {
  fbq("track", "ViewContent");
};
