// COMPONENTS
import { useState } from "react";
import { useLayoutEffect, useEffect } from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../../constants/languages/languages";
import metadata from "../../../constants/metadata";
import SEO from "../../../components/SEO";
import TitleImage from "../../../components/Screens/Solutions/TitleImage";
import InfoImage from "../../../components/Screens/Solutions/InfoImage";
import Info from "../../../components/Screens/Solutions/Info";
import styled from "styled-components";

// Facebook Pixel
import { viewContentEvent } from "../../../constants/facebookPixelEvent";

// MODAL
import Contact from "../../../components/Modal/Contact";
import DataSent from "../../../components/Modal/DataSent";
import DataSentError from "../../../components/Modal/DataSentError";
import Backdrop from "../../../components/Elements/Backdrop";
import { messageOptions } from "../../../constants/contactOptions";

// BUTTON
import CustomizableButton from "../../../components/Buttons/CustomizableButton";

// ASSETS
import Img1 from "../../../assets/Soluciones/SolucionesPersonalizadas/img1.webp";
import Img2 from "../../../assets/Soluciones/SolucionesPersonalizadas/img2.webp";
//import ImgCompany from "../../../assets/Companies/companies.webp";

// COLORS
import {
  whiteBg,
  lightBg,
  secondaryColor,
  alternativeColor,
} from "../../../style/colors";

// ALIGNS
import { textCenter, textLeft } from "../../../style/aligns";

// CONSTANTS
const titleClass = "gothamBold font-title line-height-100 primaryColor";
const descriptionClass = "gothamLight font-text blackColor";
const boldClass = "gothamBold";
// const lightClass = "lightColor";

const img = {
  alt: "",
  src: Img1,
};

/* const lineDivider = {
  width: "4%",
  border: `3px solid ${alternativeColor}`,
  padding: "0% 15% 2% 15%",
};

const company = {
  background: whiteBg,
  title: "Quiénes lo han utilizado",
  pClass: "gothamBold extraBold font50 blackColor",
  align: "textCenter",
  img: ImgCompany,
}; */

function CustomizedSolutions(props) {
  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);
  useEffect(() => {
    viewContentEvent();
  }, []);

  const [contactOpen1, toggleContact1] = useState(false);
  const [dataSentOpen1, toggleDataSent1] = useState(false);
  const [dataSentErrorOpen1, toggleDataSentError1] = useState(false);
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  const callbackFunction1 = (id) => {
    toggleContact1(!contactOpen1);
  };

  const initialBanner = {
    titleImage: {
      type: content.solutions.customizedSolutions.type,
      title: content.solutions.customizedSolutions.title,
      subTitle: content.solutions.customizedSolutions.description,
      image: {
        src: "/Soluciones/SolucionesPersonalizadas/cover.webp",
        alt: "cover",
      },
      button: {
        title: content.solutions.customizedSolutions.button,
        hover: "#ffb530",
        messageOption: messageOptions(content).customizedSolutions[0],
      },
    },
  };

  const bannerButton = {
    title: content.solutions.customizedSolutions.banners[1].button,
    border: "none",
    padding: { padding: "1% 5%" },
    background: secondaryColor,
    font: "gothamBold font-button whiteColor textLeft mobile-button-padding",
    to: "/",
    replace: false,
  };

  const introduction = {
    background: lightBg,
    title: content.solutions.customizedSolutions.banners[0].title,
    titleClass: "",
    description: (
      <div>
        <p className={descriptionClass}>
          {content.solutions.customizedSolutions.banners[0].content[0]}{" "}
          <span className={boldClass}>
            {content.solutions.customizedSolutions.banners[0].content[1]}
          </span>{" "}
        </p>
        <br />
      </div>
    ),
    descriptionClass: descriptionClass,
    align: textCenter,
    padding: "3% 18% 1% 18%",
    isNotP: true,
  };

  const banners = [
    {
      id: 1,
      background: whiteBg,
      txtAlign: textLeft,
      title: content.solutions.customizedSolutions.banners[1].title,
      titleClass: titleClass,
      description: (
        <div>
          <p className={descriptionClass}>
            {content.solutions.customizedSolutions.banners[1].content[0]}
          </p>
          <br />
          <div style={{ padding: "0 30% 0 0" }}>
            <CustomizableButton
              id={0}
              font={bannerButton.font}
              title={bannerButton.title.toUpperCase()}
              isCallback={true}
              callBack={callbackFunction1}
              background={bannerButton.background}
              border={"none"}
              margin={"3% 3% 3% 0%"}
              padding={"3% 6%"}
              hover={{
                border: "none",
                background: alternativeColor,
              }}
            />
          </div>
        </div>
      ),
      rightImg: true,
      img: Img2,
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
  ];

  return (
    <Wrapper>
      <SEO metadata={metadata.customizedSolutions} />
      {/*-------------------------------- MODAL 1 --------------------------------*/}
      {/* DataSent Modal*/}
      <DataSent dataSentOpen={dataSentOpen1} toggleDataSent={toggleDataSent1} />
      {dataSentOpen1 && <Backdrop toggle={toggleDataSent1} />}
      {/* DataSentError Modal*/}
      <DataSentError
        dataSentErrorOpen={dataSentErrorOpen1}
        toggleDataSentError={toggleDataSentError1}
      />
      {dataSentErrorOpen1 && <Backdrop toggle={toggleDataSentError1} />}
      {/* Contact Modal*/}
      <Contact
        contactOpen={contactOpen1}
        toggleContact={toggleContact1}
        dataSentOpen={dataSentOpen1}
        toggleDataSent={toggleDataSent1}
        dataSentErrorOpen={dataSentErrorOpen1}
        toggleDataErrorSent={toggleDataSentError1}
        option={messageOptions(content).customizedSolutions[1].option}
        message={messageOptions(content).customizedSolutions[1].message}
      />
      {contactOpen1 && <Backdrop toggle={toggleContact1} />}
      {/*-----------------------------------------------------------------------*/}

      <TitleImage
        type={initialBanner.titleImage.type}
        title={initialBanner.titleImage.title}
        subTitle={initialBanner.titleImage.subTitle}
        image={initialBanner.titleImage.image}
        button={initialBanner.titleImage.button}
      />
      <div className="col-lg d-flex justify-content-center mobile-section-vertical-padding">
        <ImgStyle
          title={img.alt}
          loading="lazy"
          src={img.src}
          alt={img.alt}
          width="auto"
          height="auto"
          style={{ width: "40%", maxWidth: "100%", minWidth: "350px" }}
        />
      </div>
      <Info
        background={introduction.background}
        title={introduction.title}
        titleClass={introduction.titleClass}
        description={introduction.description}
        descriptionClass={introduction.descriptionClass}
        align={introduction.align}
        padding={introduction.padding}
        isNotP={introduction.isNotP}
      />
      {banners.map((elem) => {
        return (
          <InfoImage
            key={elem.id}
            id={elem.id}
            background={elem.background}
            txtAlign={elem.txtAlign}
            title={elem.title}
            titleClass={elem.titleClass}
            description={elem.description}
            rightImg={elem.rightImg}
            img={elem.img}
            imgPadding={elem.imgPadding}
          />
        );
      })}
      {/* <LineDivider
        width={lineDivider.width}
        border={lineDivider.border}
        padding={lineDivider.padding}
      />
      <CompanyImage
        background={company.background}
        title={company.title}
        pClass={company.pClass}
        align={company.align}
        img={company.img}
      /> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 0 5% 0;
`;

const ImgStyle = styled.img`
  padding: 1%;
`;

export default CustomizedSolutions;
