import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import FooterWrapper from "./components/Footer/FooterWrapper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import "./style/index.css";

function getCompleteComponent(component) {
  return (
    <HelmetProvider>
      <BrowserRouter>{component}</BrowserRouter>
    </HelmetProvider>
  );
}

const rootElement = document.getElementById("root");
const footerElement = document.getElementById("footer");

const rootComponent = getCompleteComponent(<App />);
const footerComponent = getCompleteComponent(<FooterWrapper />);

ReactDOM.createRoot(rootElement).render(rootComponent);
ReactDOM.createRoot(footerElement).render(footerComponent);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
