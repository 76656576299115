import { useState, useLayoutEffect, useEffect } from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../../constants/languages/languages";
import styled from "styled-components";
import metadata from "../../../constants/metadata";
import SEO from "../../../components/SEO";

// Facebook Pixel
import { viewContentEvent } from "../../../constants/facebookPixelEvent";

// COMPONENTS
import TitleImage from "../../../components/Screens/Solutions/TitleImage";
import InfoImage from "../../../components/Screens/Solutions/InfoImage";

// MODAL
import Contact from "../../../components/Modal/Contact";
import DataSent from "../../../components/Modal/DataSent";
import DataSentError from "../../../components/Modal/DataSentError";
import Backdrop from "../../../components/Elements/Backdrop";
import { messageOptions } from "../../../constants/contactOptions";

// BUTTON
import Solution from "../../../components/Screens/Solutions/Solution";

// ASSETS
import Img1 from "../../../assets/Soluciones/PlaneacionProduccion/img1.webp";
import Img2 from "../../../assets/Soluciones/PlaneacionProduccion/img2.webp";
import Img3 from "../../../assets/Soluciones/PlaneacionProduccion/img3.webp";
import Img4 from "../../../assets/Soluciones/PlaneacionProduccion/img4.webp";

// COLORS
import { whiteBg, lightBg, secondaryColor } from "../../../style/colors";

// ALIGNS
import { textLeft, textRight } from "../../../style/aligns";

// CONSTANTS
const titleClass = "gothamBold font-title line-height-100 primaryColor";
const descriptionClass = "gothamLight font-text blackColor";
const boldClass = "gothamBold";
// const lightClass = "lightColor";

function ProductionPlanning(props) {
  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);
  useEffect(() => {
    viewContentEvent();
  }, []);

  const [contactOpen, toggleContact] = useState(false);
  const [dataSentOpen, toggleDataSent] = useState(false);
  const [dataSentErrorOpen, toggleDataSentError] = useState(false);
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  const callbackFunction = (id) => {
    toggleContact(!contactOpen);
  };

  const initialBanner = {
    titleImage: {
      type: content.solutions.productionPlanning.type,
      title: content.solutions.productionPlanning.title,
      subTitle: content.solutions.productionPlanning.description,
      image: {
        src: "/Soluciones/PlaneacionProduccion/cover.webp",
        alt: "cover",
      },
      button: {
        title: content.solutions.productionPlanning.button,
        hover: "#ffb530",
        messageOption: messageOptions(content).productionPlanning[0],
      },
    },
  };

  const button = {
    title: content.solutions.productionPlanning.button,
    border: "none",
    padding: { padding: "2% 15%" },
    background: secondaryColor,
    font: "gothamBold font-button whiteColor",
    to: "/",
    replace: false,
  };

  const banners = [
    {
      id: 1,
      background: whiteBg,
      txtAlign: textLeft,
      title: content.solutions.productionPlanning.banners[0].title,
      titleClass: titleClass,
      description: (
        <div>
          <p className={descriptionClass}>
            {content.solutions.productionPlanning.banners[0].content[0]}{" "}
            <span className={boldClass}>
              {content.solutions.productionPlanning.banners[0].content[1]}
            </span>{" "}
          </p>
          <br />
          <p className={descriptionClass}>
            {content.solutions.productionPlanning.banners[0].content[2]}
          </p>
        </div>
      ),
      rightImg: true,
      img: Img1,
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 2,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.productionPlanning.banners[1].title,
      titleClass: titleClass,
      description: (
        <div>
          <p className={descriptionClass}>
            {content.solutions.productionPlanning.banners[1].content[0]}
          </p>
        </div>
      ),
      rightImg: false,
      img: Img2,
      imgPadding: { padding: "0 28%", maxWidth: "100%" },
    },
    {
      id: 3,
      background: whiteBg,
      txtAlign: textRight,
      title: content.solutions.productionPlanning.banners[2].title,
      titleClass: `${titleClass} ${textRight}`,
      description: (
        <div>
          <p className={descriptionClass}>
            {content.solutions.productionPlanning.banners[2].content[0]}{" "}
            <span className={boldClass}>
              {content.solutions.productionPlanning.banners[2].content[1]}
            </span>
            {content.solutions.productionPlanning.banners[2].content[2]}
          </p>
        </div>
      ),
      rightImg: true,
      img: Img3,
      imgPadding: { padding: "0 25%", maxWidth: "100%" },
    },
    {
      id: 4,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.productionPlanning.banners[3].title,
      titleClass: titleClass,
      description: (
        <div>
          <p className={descriptionClass}>
            {content.solutions.productionPlanning.banners[3].content[0]}
          </p>
        </div>
      ),
      rightImg: false,
      img: Img4,
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
  ];

  return (
    <Wrapper>
      <SEO metadata={metadata.productionPlanning} />
      {/*-------------------------------- MODALS --------------------------------*/}
      {/* DataSent Modal*/}
      <DataSent dataSentOpen={dataSentOpen} toggleDataSent={toggleDataSent} />
      {dataSentOpen && <Backdrop toggle={toggleDataSent} />}
      {/* DataSentError Modal*/}
      <DataSentError
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataSentError={toggleDataSentError}
      />
      {dataSentErrorOpen && <Backdrop toggle={toggleDataSentError} />}
      {/* Contact Modal*/}
      <Contact
        contactOpen={contactOpen}
        toggleContact={toggleContact}
        dataSentOpen={dataSentOpen}
        toggleDataSent={toggleDataSent}
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataErrorSent={toggleDataSentError}
        option={messageOptions(content).productionPlanning[0].option}
        message={messageOptions(content).productionPlanning[0].message}
      />
      {contactOpen && <Backdrop toggle={toggleContact} />}
      {/*-----------------------------------------------------------------------*/}

      <TitleImage
        type={initialBanner.titleImage.type}
        title={initialBanner.titleImage.title}
        subTitle={initialBanner.titleImage.subTitle}
        image={initialBanner.titleImage.image}
        button={initialBanner.titleImage.button}
      />
      {banners.map((elem) => {
        return (
          <InfoImage
            key={elem.id}
            id={elem.id}
            background={elem.background}
            txtAlign={elem.txtAlign}
            title={elem.title}
            titleClass={elem.titleClass}
            description={elem.description}
            rightImg={elem.rightImg}
            img={elem.img}
            imgPadding={elem.imgPadding}
          />
        );
      })}
      <Solution
        title={button.title}
        font={button.font}
        background={button.background}
        border={button.border}
        innerPadding={"1.2% 5%"}
        to={button.to}
        replace={false}
        padding={button.padding}
        callBack={callbackFunction}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 0 5% 0;
`;

export default ProductionPlanning;
