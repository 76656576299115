import React from "react";
import styled from "styled-components";

export default function Backdrop({ toggle, transparent, toggleId }) {
  if (toggleId) {
    return <TransparentWrapper onClick={() => toggle(toggleId, false)} />;
  }
  if (transparent) {
    return <TransparentWrapper onClick={() => toggle(false)} />;
  }
  return <Wrapper className="darkBg" onClick={() => toggle(false)} />;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0.8;
`;

const TransparentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 1;
`;
