import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../constants/languages/languages";
import styled from "styled-components";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

// Facebook Pixel
import { contactEvent } from "../../constants/facebookPixelEvent";

import CustomizableButton from "../Buttons/CustomizableButton";
import ContactBanner from "./ContactBanner";
import {
  secondaryColor,
  alternativeColor,
  initial,
  error,
  success,
} from "../../style/colors";
import { textLeft } from "../../style/aligns";
import {
  getValidStatus,
  getEmailValidStatus,
  getPhoneStatus,
  getAreaStatus,
  getInformationStatus,
} from "../../constants/regex";
import { sendContactInfo, formatBody } from "../../constants/sendContactInfo";

// Assets
import Img1 from "../../assets/Contact/img1.webp";

// SOCIAL MEDIA ICONS
import WhatsApp from "../../assets/svg/WhatsApp";
import Facebook from "../../assets/svg/Facebook";
import Instagram from "../../assets/svg/Instagram";
import LinkedIn from "../../assets/svg/LinkedIn";

const CenterIcon = styled.a`
  margin: 0 10px;
  &:hover {
    color: #2d96a7 !important;
  }
`;
const LeftIcon = styled.a`
  margin: 0 10px 0 0;
  &:hover {
    color: #2d96a7 !important;
  }
`;
const RightIcon = styled.a`
  margin: 0 0 0 10px;
  &:hover {
    color: #2d96a7 !important;
  }
`;

// CONSTANTS
const titleClass = "gothamBold font-title line-height-100 primaryColor";
const descriptionClass = "gothamLight font-text blackColor";
const contactDataClass = "gothamLight font15 blackColor";
const boldClass = "gothamBold font-text blackColor";

const getOptions = (options) => {
  return options.map((elem, index) =>
    index === 0 ? (
      <Option className="option-font" key={index} value="" disabled selected>
        {elem}
      </Option>
    ) : (
      <Option className="option-font" key={index} value={index}>
        {elem}
      </Option>
    )
  );
};

const reset = (ref, setStatus) => {
  ref.current.value = "";
  setStatus(initial);
};

export default function Contact({
  contactOpen,
  toggleContact,
  dataSentOpen,
  toggleDataSent,
  dataSentErrorOpen,
  toggleDataErrorSent,
  option,
  message,
}) {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  useEffect(() => {
    if (contactOpen) {
      contactEvent();
    }
  }, [contactOpen]);

  // useRef **************************************************************************
  const nameRef = useRef();
  const lastNameRef = useRef();
  const companyRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const areaRef = useRef();
  const informationRef = useRef();

  // useStates **********************************************************************
  const [names, setNames] = useState(-1);
  const [lastNames, setLastNames] = useState(-1);
  const [country, setCountry] = useState(-1);
  const [dialCode, setDialCode] = useState(-1);
  const [phone, setPhone] = useState(-1);
  const [email, setEmail] = useState(-1);

  const [area, setArea] = useState(-1);
  const [information, setInformation] = useState(-1);

  // status
  const [namesStatus, setNamesStatus] = useState(initial);
  const [lastNamesStatus, setLastNamesStatus] = useState(initial);
  const [phoneStatus, setPhoneStatus] = useState(initial);
  const [emailStatus, setEmailStatus] = useState(initial);

  const [areaStatus, setAreaStatus] = useState(initial);
  const [informationStatus, setInformationStatus] = useState(initial);

  // useEffects **********************************************************************
  useEffect(() => {
    const getNameResult = setTimeout(() => {
      setNamesStatus(getValidStatus(names));
    }, 200);
    return () => clearTimeout(getNameResult);
  }, [names]);

  useEffect(() => {
    const getLastNameResult = setTimeout(() => {
      setLastNamesStatus(getValidStatus(lastNames));
    }, 200);
    return () => clearTimeout(getLastNameResult);
  }, [lastNames]);

  useEffect(() => {
    const getEmailResult = setTimeout(() => {
      setEmailStatus(getEmailValidStatus(email));
    }, 200);
    return () => clearTimeout(getEmailResult);
  }, [email]);

  useEffect(() => {
    const getPhoneResult = setTimeout(() => {
      setPhoneStatus(getPhoneStatus(phone));
    }, 200);
    return () => clearTimeout(getPhoneResult);
  }, [phone]);

  useEffect(() => {
    const getAreaResult = setTimeout(() => {
      setAreaStatus(getAreaStatus(area));
    }, 200);
    return () => clearTimeout(getAreaResult);
  }, [area]);

  useEffect(() => {
    const getInformationResult = setTimeout(() => {
      setInformationStatus(getInformationStatus(information));
    }, 200);
    return () => clearTimeout(getInformationResult);
  }, [information]);

  useEffect(() => {
    if (option && message) {
      messageRef.current.value = message;
    }
  }, [option, message]);

  // **********************************************************************

  const saveFunction = (id) => {
    if (option) {
      setInformationStatus(success);
    }
    if (
      namesStatus === success &&
      lastNamesStatus === success &&
      emailStatus === success &&
      phoneStatus === success &&
      areaStatus === success &&
      (informationStatus === success || option)
    ) {
      sendContactInfo(
        dataSentOpen,
        toggleDataSent,
        dataSentErrorOpen,
        toggleDataErrorSent,
        formatBody(
          nameRef.current.value.trim(),
          lastNameRef.current.value.trim(),
          companyRef.current.value.trim(),
          emailRef.current.value.trim(),
          country,
          dialCode,
          phone,
          informationRef.current.value,
          messageRef.current.value.trim(),
          areaRef.current.value
        )
      );
      reset(nameRef, setNamesStatus);
      reset(lastNameRef, setLastNamesStatus);
      reset(emailRef, setEmailStatus);

      reset(areaRef, setAreaStatus);
      reset(informationRef, setInformationStatus);

      companyRef.current.value = "";
      messageRef.current.value = "";

      setCountry(-1);
      setDialCode(-1);
      setPhone(-1);
      toggleContact(!contactOpen);
      //toggleDataSent(!dataSentOpen);
    }

    if (namesStatus !== success) {
      nameRef.current.focus();
      return;
    }
    if (lastNamesStatus !== success) {
      lastNameRef.current.focus();
      return;
    }
    if (emailStatus !== success) {
      emailRef.current.focus();
      return;
    }
    if (areaStatus !== success) {
      areaRef.current.focus();
      return;
    }
    if (informationStatus !== success) {
      informationRef.current.focus();
      return;
    }
    if (phoneStatus !== success) {
      setPhoneStatus("red");
      return;
    }
  };

  const handlePhoneChange = (status, phoneNumber, country) => {
    setCountry(country.name);
    setDialCode(country.dialCode);
    setPhone(phoneNumber);
  };

  const banner = {
    id: 4,
    background: alternativeColor,
    txtAlign: textLeft,
    title: content.contact.title,
    titleClass: titleClass,
    description: (
      <div>
        <p className={descriptionClass}>{content.contact.description}</p>
        <br />
        <p className={boldClass}>{content.contact.content[0]}</p>
        <br />
        <p className={`${contactDataClass} contact`}>
          <a href="mailto:info@scilambda.net">E-mail: info@scilambda.net</a>
        </p>
        <p className={`${contactDataClass} contact`}>
          <a href="tel:+502 23010853">PBX: +(502) 2301-0853</a>
        </p>
        <div className="d-flex justify-content-start">
          <LeftIcon
            href={`https://api.whatsapp.com/send?phone=50223010853&text=${content.footer.socialMediaMessages.whatsApp}`}
          >
            <WhatsApp />
          </LeftIcon>
          <CenterIcon href="https://www.facebook.com/scilambda">
            <Facebook />
          </CenterIcon>
          <CenterIcon href="https://www.instagram.com/lambdagt/">
            <Instagram />
          </CenterIcon>
          <RightIcon href="https://www.linkedin.com/company/scilambda/">
            <LinkedIn />
          </RightIcon>
        </div>
        <br />
      </div>
    ),
    rightImg: true,
    img: Img1,
    padding: "1% 5%",
    imgPadding: { padding: "0 15%", maxWidth: "100%" },
  };

  const options = content.contact.moreInfoOptions;
  const areaOptions = content.contact.areaOptions;

  // **********************************************************************
  return (
    <Wrapper
      className="animate flex flexColumn scrollModal"
      contactOpen={contactOpen}
    >
      <div
        className="alternativeBg topRadius8"
        style={{ padding: "2% 3% 0% 2%" }}
      >
        <div className="row">
          <div className="col">
            <ContactBanner
              key={banner.id}
              id={banner.id}
              background={banner.background}
              txtAlign={banner.txtAlign}
              title={banner.title}
              titleClass={banner.titleClass}
              description={banner.description}
              rightImg={banner.rightImg}
              img={banner.img}
              padding={banner.padding}
              imgPadding={banner.imgPadding}
              toggleContact={toggleContact}
              contactOpen={contactOpen}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center bottomRadius8 whiteBg"
        style={{
          width: "100%",
          height: "fixed",
          padding: "1%",
          flex: "1 1 auto",
        }}
      >
        <div className="col">
          <div className="row">
            <div className="col-lg marginAll">
              {/* NAME INPUT */}
              <Input
                ref={nameRef}
                className="innerInput font18 input97"
                placeholder={`${content.contact.inputs.names}*`}
                onChange={(event) => setNames(event.target.value)}
                onFocus={(event) => setNames(event.target.value)}
                style={{ border: `1px solid ${namesStatus}` }}
              />
            </div>
            <div className="col-lg marginAll">
              {/* LASTNAMES INPUT */}
              <Input
                ref={lastNameRef}
                className="innerInput font18 input97"
                placeholder={`${content.contact.inputs.lastNames}*`}
                onChange={(event) => setLastNames(event.target.value)}
                onFocus={(event) => setLastNames(event.target.value)}
                style={{ border: `1px solid ${lastNamesStatus}` }}
              />
            </div>
          </div>
          <div className="row  d-flex align-items-start">
            <div className="col-lg marginAll">
              {/* EMAIL INPUT */}
              <Input
                ref={emailRef}
                className="innerInput font18 input97"
                placeholder={`${content.contact.inputs.email}*`}
                onChange={(event) => setEmail(event.target.value)}
                onFocus={(event) => setEmail(event.target.value)}
                style={{ border: `1px solid ${emailStatus}` }}
              />
            </div>
            <div className="col-lg marginAll">
              {/* WORKING AREA TYPE INPUT */}
              {/*<p className="gothamLight font15 blackColor">
                Sector donde trabaja:
              </p>*/}
              <Select
                ref={areaRef}
                className="innerInput font18 input97"
                onChange={(event) => setArea(event.target.value)}
                onFocus={(event) => setArea(event.target.value)}
                style={{ border: `1px solid ${areaStatus}` }}
              >
                {getOptions(areaOptions)}
              </Select>
            </div>
          </div>
          <div className="row d-flex align-items-start">
            <div className="col-lg marginAll">
              {/* COMPANY INPUT */}
              <Input
                ref={companyRef}
                className="innerInput font18 input97"
                placeholder={content.contact.inputs.company}
                style={{ border: "1px solid gray" }}
              />
            </div>
            <div className="col-lg marginAll h100">
              {/* INFORMATION TYPE INPUT */}
              {option ? (
                <Select
                  ref={informationRef}
                  className="innerInput font18 input97"
                  defaultValue={option}
                  onChange={(event) => setInformation(event.target.value)}
                  onFocus={(event) => setInformation(event.target.value)}
                  style={{ border: `1px solid green` }}
                >
                  {getOptions(options)}
                </Select>
              ) : (
                <Select
                  ref={informationRef}
                  className="innerInput font18 input97"
                  onChange={(event) => setInformation(event.target.value)}
                  onFocus={(event) => setInformation(event.target.value)}
                  style={{ border: `1px solid ${informationStatus}` }}
                >
                  {getOptions(options)}
                </Select>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg marginAll">
              {/* MESSAGE INPUT */}
              <TextArea
                ref={messageRef}
                className="innerInput font18 input97"
                placeholder={content.contact.inputs.message}
                defaultValue={message ? message : ""}
                rows="4"
                style={{ border: "1px solid gray" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg marginAll input97">
              {/* PHONE INPUT */}
              <IntlTelInput
                placeholder={`${content.contact.inputs.phone}*`}
                input
                type="tel"
                containerClassName="intl-tel-input"
                inputClassName="form-control"
                preferredCountries={["gt"]}
                onPhoneNumberChange={handlePhoneChange}
                value={phone === -1 ? "" : phone}
                style={{
                  border: `1px solid ${phoneStatus}`,
                  borderRadius: "0.8rem",
                }}
                onPhoneNumberFocus={() => {
                  if (phoneStatus === initial) setPhoneStatus(error);
                }}
              />
            </div>
            <div className="col-lg marginAll input97">
              <CustomizableButton
                id={0}
                font={"gothamBold font20 whiteColor"}
                title={content.contact.button}
                isCallback={true}
                callBack={saveFunction}
                background={secondaryColor}
                border={"none"}
                hover={{
                  border: "none",
                  background: alternativeColor,
                }}
                margin={"0%"}
                width={"100%"}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 70%;
  height: 98%;
  position: fixed;
  opacity: 1;
  top: 1%;
  padding: 0%;
  left: ${(props) => (props.contactOpen ? "15%" : "-200%")};
  z-index: 9999;
  @media (max-width: 859px) {
    width: 96%;
    height: 94%;
    top: 3%;
    left: ${(props) => (props.contactOpen ? "2%" : "-200%")};
  }
`;

const Input = styled.input`
  border-radius: 0.6rem;
  padding: 1%;
  width: 100%;
  font-family: "Gotham-Light";
`;
const TextArea = styled.textarea`
  border-radius: 0.6rem;
  padding: 1%;
  width: 100%;
  height: 7.5vw;
  min-height: 100px;
  font-family: "Gotham-Light";
`;
const Select = styled.select`
  border-radius: 0.6rem;
  background: white;
  padding: 1%;
  width: 100%;
  border: 1px solid gray;
  font-family: "Gotham-Light";
`;
const Option = styled.option`
  border-radius: 0.6rem;
  padding: 1%;
  width: 100%;
  font-family: "Gotham-Light";
`;
