import { useSelector } from "react-redux";
import { getContent } from "../../../constants/languages/languages";

import styled from "styled-components";
import Image from "../../../assets/WeAreLambda/we-are-lambda.webp";

function WeAreLambda(props) {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);
  return (
    <div
      id="weAre"
      className="lightBg mobile-section-horizontal-padding"
      style={{ padding: "3% 15%", margin: "0px", width: "100%" }}
    >
      <div className="row">
        <div className="col-lg">
          <p
            className="gothamBold font-title primaryColor textLeft"
            style={{ margin: "0px" }}
          >
            {content.home.weAre.title}
          </p>
          <br />
          <p className="gothamLight font-text blackColor">
            {content.home.weAre.content[0]}
          </p>
          <br />
          <p className="gothamLight font-text blackColor">
            {content.home.weAre.content[1]}
          </p>
        </div>
        <div className="col-lg" style={{ padding: "0px", margin: "0px" }}>
          <ImgStyle
            title=""
            loading="lazy"
            src={Image}
            alt=""
            width="auto"
            height="auto"
          />
        </div>
      </div>
    </div>
  );
}

const ImgStyle = styled.img`
  width: 100%;
  padding: 8%;
`;

export default WeAreLambda;
