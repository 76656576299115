import { useLayoutEffect, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../../constants/languages/languages";
import styled from "styled-components";
import metadata from "../../../constants/metadata";
import SEO from "../../../components/SEO";

// Facebook Pixel
import { viewContentEvent } from "../../../constants/facebookPixelEvent";

// COMPONENTS
import TitleImage from "../../../components/Screens/Solutions/TitleImage";
import InfoImage from "../../../components/Screens/Solutions/InfoImage";
import LineDivider from "../../../components/Utils/LineDivider";
import Info from "../../../components/Screens/Solutions/Info";

// MODAL
import Contact from "../../../components/Modal/Contact";
import DataSent from "../../../components/Modal/DataSent";
import DataSentError from "../../../components/Modal/DataSentError";
import Backdrop from "../../../components/Elements/Backdrop";
import { messageOptions } from "../../../constants/contactOptions";

// BUTTON
import Solution from "../../../components/Screens/Solutions/Solution";

// ASSETS
import Img1 from "../../../assets/Soluciones/ClientesPotenciales/img1.webp";

// COLORS
import {
  whiteBg,
  lightBg,
  secondaryColor,
  alternativeColor,
} from "../../../style/colors";

// ALIGNS
import { textLeft, textCenter } from "../../../style/aligns";

// CONSTANTS
const titleClass = "gothamBold font-title line-height-100 primaryColor";
const descriptionClass = "gothamLight font-text blackColor";
//const boldClass = "gothamBold";
// const lightClass = "lightColor";

const lineDivider = {
  width: "4%",
  border: `3px solid ${alternativeColor}`,
  padding: "0% 15% 2% 15%",
};

function PotentialCustomers(props) {
  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);
  useEffect(() => {
    viewContentEvent();
  }, []);

  const [contactOpen, toggleContact] = useState(false);
  const [dataSentOpen, toggleDataSent] = useState(false);
  const [dataSentErrorOpen, toggleDataSentError] = useState(false);
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  const callbackFunction = (id) => {
    toggleContact(!contactOpen);
  };

  const initialBanner = {
    titleImage: {
      type: content.solutions.potentialCustomers.type,
      title: content.solutions.potentialCustomers.title,
      subTitle: content.solutions.potentialCustomers.description,
      image: {
        src: "/Soluciones/ClientesPotenciales/cover.webp",
        alt: "cover",
      },
      button: {
        title: content.solutions.potentialCustomers.button,
        hover: "#ffb530",
        messageOption: messageOptions(content).potentialCustomers[0],
      },
    },
  };

  const introduction = {
    background: lightBg,
    title: content.solutions.potentialCustomers.banners[0].title,
    titleClass: "",
    description: content.solutions.potentialCustomers.banners[0].content[0],
    descriptionClass: descriptionClass,
    align: textCenter,
  };

  const banners = [
    {
      id: 1,
      background: whiteBg,
      txtAlign: textLeft,
      title: content.solutions.potentialCustomers.banners[1].title,
      titleClass: titleClass,
      description: (
        <div>
          <p className={descriptionClass}>
            {content.solutions.potentialCustomers.banners[1].content[0]}
          </p>
          <br />
          <p className={descriptionClass}>
            {content.solutions.potentialCustomers.banners[1].content[1]}
          </p>
          <br />
          <br />
          <p className={descriptionClass}>
            {content.solutions.potentialCustomers.banners[1].content[2]}
          </p>
        </div>
      ),
      rightImg: true,
      img: Img1,
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
  ];

  const list = {
    background: whiteBg,
    title: "",
    titleClass: "",
    description: (
      <div>
        <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
          {content.solutions.potentialCustomers.banners[1].list.map(
            (item, index) => (
              <li key={index}>{item}</li>
            )
          )}
        </ol>
      </div>
    ),
    descriptionClass: descriptionClass,
    align: textLeft,
    padding: "0% 15% 5% 15%",
    isNotP: true,
  };

  const button = {
    title: content.solutions.potentialCustomers.finalSection.button,
    border: "none",
    padding: { padding: "2% 15%" },
    background: secondaryColor,
    font: "gothamBold font-button whiteColor",
    to: "/",
    replace: false,
  };

  return (
    <Wrapper>
      <SEO metadata={metadata.potentialCustomers} />
      {/*-------------------------------- MODALS --------------------------------*/}
      {/* DataSent Modal*/}
      <DataSent dataSentOpen={dataSentOpen} toggleDataSent={toggleDataSent} />
      {dataSentOpen && <Backdrop toggle={toggleDataSent} />}
      {/* DataSentError Modal*/}
      <DataSentError
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataSentError={toggleDataSentError}
      />
      {dataSentErrorOpen && <Backdrop toggle={toggleDataSentError} />}
      {/* Contact Modal*/}
      <Contact
        contactOpen={contactOpen}
        toggleContact={toggleContact}
        dataSentOpen={dataSentOpen}
        toggleDataSent={toggleDataSent}
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataErrorSent={toggleDataSentError}
        option={messageOptions(content).potentialCustomers[1].option}
        message={messageOptions(content).potentialCustomers[1].message}
      />
      {contactOpen && <Backdrop toggle={toggleContact} />}
      {/*-----------------------------------------------------------------------*/}

      <TitleImage
        type={initialBanner.titleImage.type}
        title={initialBanner.titleImage.title}
        subTitle={initialBanner.titleImage.subTitle}
        image={initialBanner.titleImage.image}
        button={initialBanner.titleImage.button}
      />
      <Info
        background={introduction.background}
        title={introduction.title}
        titleClass={introduction.titleClass}
        description={introduction.description}
        descriptionClass={introduction.descriptionClass}
        align={introduction.align}
      />
      {banners.map((elem) => {
        return (
          <InfoImage
            key={elem.id}
            id={elem.id}
            background={elem.background}
            txtAlign={elem.txtAlign}
            title={elem.title}
            titleClass={elem.titleClass}
            description={elem.description}
            rightImg={elem.rightImg}
            img={elem.img}
            imgPadding={elem.imgPadding}
          />
        );
      })}
      <Info
        background={list.background}
        title={list.title}
        titleClass={list.titleClass}
        description={list.description}
        descriptionClass={list.descriptionClass}
        align={list.align}
        padding={list.padding}
        isNotP={list.isNotP}
      />
      <LineDivider
        width={lineDivider.width}
        border={lineDivider.border}
        padding={lineDivider.padding}
      />
      <Solution
        title={button.title}
        font={button.font}
        background={button.background}
        border={button.border}
        innerPadding={"1.2% 5%"}
        to={button.to}
        replace={false}
        padding={button.padding}
        callBack={callbackFunction}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 0 5% 0;
`;

export default PotentialCustomers;
