import React from "react";
import { Provider } from "react-redux";
import Footer from "./Footer.jsx";
import store from "../../app/store.js";

export default function FooterWrapper() {
  return (
    <Provider store={store}>
      <Footer />
    </Provider>
  );
}
