import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setBlogInfo } from "../../app/slices/blogInfoSlice";
import { request } from "../../api/request";
import { getContentOf } from "../../api/endpoints/blogs";
import { textLeft } from "../../style/aligns";
import { whiteBg } from "../../style/colors";
import { baseURL } from "../../api/config";
import { OK } from "../../api/statusCodes";
import { getContent } from "../../constants/languages/languages";
import metadata from "../../constants/metadata";

import styled from "styled-components";
import InfoImage from "../../components/Screens/Solutions/InfoImage";
import LoadingSpinner from "../../components/Spinners/LoadingSpinner";
import TextButton from "../../components/Buttons/TextButton";
import BlogContent from "../../components/Blogs/BlogContent";
import BlogTitle from "../../components/Blogs/BlogTitle";
import Img1 from "./../../assets/WeAreLambda/icon2.webp";
import SEO from "../../components/SEO";

function BlogInfoScreen(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const blogInfo = useSelector((state) => state.blogInfo.data);
  const [spinnerVisibility, setSpinnerVisibility] = useState(
    !blogInfo || blogInfo.url !== params.url
  );
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);

  // SEND REQUEST ----------------------------------------
  useEffect(() => {
    async function fetchData() {
      if (!blogInfo || blogInfo.url !== params.url) {
        const { status, data } = await request(...getContentOf(params.url));
        if (status === OK) {
          dispatch(setBlogInfo(data));
        }
        setSpinnerVisibility(false);
      }
    }
    fetchData();
  }, [dispatch, params.url, blogInfo, language]);

  const banners = [
    {
      id: 1,
      background: whiteBg,
      txtAlign: textLeft,
      title: content.blogs.infoScreen.title,
      description: (
        <div>
          <br />
          <p className={"gothamLight font-text blackColor"}>
            {content.blogs.infoScreen.content[0]}
          </p>
          <br />
          <p className={"gothamLight font-text blackColor"}>
            {content.blogs.infoScreen.content[1]}{" "}
            <span>
              <TextButton
                title={content.blogs.infoScreen.button}
                to="/blog"
                className="font-text secondaryColor"
              />
            </span>
            .
          </p>
        </div>
      ),
      rightImg: true,
      img: Img1,
      titleClass: `gothamBold font-title primaryColor ${textLeft}`,
    },
  ];

  if (spinnerVisibility) {
    return <LoadingSpinner />;
  }

  if (blogInfo) {
    const hashtags = blogInfo.hashtags.map((e) => `#${e}`);
    const blogMetadata = {
      title: blogInfo.title,
      description: blogInfo.preview,
      href: `${baseURL}/blog/${blogInfo.title}`,
    };

    return (
      <Wrapper id={"initial"}>
        <SEO metadata={metadata[params.url] || blogMetadata} />
        <div className="blog-container">
          <BlogTitle
            title={blogInfo.title}
            topic={blogInfo.topic}
            hashtags={hashtags}
            image={blogInfo.image.$oid}
            date={blogInfo.creation_date}
          />
          <br />
          <BlogContent content={blogInfo.content} />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper id={"initial"}>
      <SEO metadata={metadata.home} />
      {banners.map((elem) => {
        return (
          <InfoImage
            key={elem.id}
            id={elem.id}
            background={elem.background}
            txtAlign={elem.txtAlign}
            title={elem.title}
            description={elem.description}
            rightImg={elem.rightImg}
            img={elem.img}
            titleClass={elem.titleClass}
            imgPadding={{ padding: "10%" }}
          />
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 70px 0 5% 0;
`;

export default BlogInfoScreen;
