import { useSelector } from "react-redux";
import { getContent } from "../../constants/languages/languages";
import styled from "styled-components";

// SOCIAL MEDIA ICONS
import WhatsApp from "../../assets/svg/WhatsApp";
import Facebook from "../../assets/svg/Facebook";
import Instagram from "../../assets/svg/Instagram";
import LinkedIn from "../../assets/svg/LinkedIn";

export default function Footer() {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="primaryBg">
        <div className="container flexColumn flexSpaceCenter ">
          <InnerWrapper
            className="row"
            style={{ width: "100%", padding: "15px 0" }}
          >
            <p className="col-sm pirulen font-subtitle whiteColor textLeft">
              LAMBDA
            </p>
            <div className="col-sm">
              <p className="col-sm gothamBold font-subtitle  whiteColor textRight">
                {content.footer.contact}
              </p>
              <p className="col-sm gothamLight font15 whiteColor textRight">
                <a href="mailto:info@scilambda.net">
                  E-mail: info@scilambda.net
                </a>
              </p>
              <p className="col-sm gothamLight font15 whiteColor textRight">
                <a href="tel:+502 23010853">PBX: +(502) 2301-0853</a>
              </p>
              <IconPadding className="d-flex justify-content-end">
                <LeftIcon
                  href={`https://api.whatsapp.com/send?phone=50223010853&text=${content.footer.socialMediaMessages.whatsApp}`}
                >
                  <WhatsApp />
                </LeftIcon>
                <CenterIcon href="https://www.facebook.com/scilambda">
                  <Facebook />
                </CenterIcon>
                <CenterIcon href="https://www.instagram.com/lambdagt/">
                  <Instagram />
                </CenterIcon>
                <RightIcon href="https://www.linkedin.com/company/scilambda/">
                  <LinkedIn />
                </RightIcon>
              </IconPadding>
            </div>
          </InnerWrapper>
          <InnerWrapper
            className="row"
            style={{ width: "100%", padding: "15px 0" }}
          >
            <StyleP className="col-sm gothamLight whiteColor font-text">
              © {getCurrentYear()} -{" "}
              <span className="gothamLight secondaryColor font-text">
                SciLambda, S.A.
              </span>{" "}
              {content.footer.text}
            </StyleP>
            <div className="col-sm">
              <div className="col-sm gothamLight font-text textRight">
                <A className="pointer" href={"/politica-de-privacidad"}>
                  {content.footer.privacyPolicy}
                </A>
              </div>
            </div>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const A = styled.a`
  color: #2d96a7 !important;
  &:hover {
    color: #ffb530 !important;
  }
`;
const CenterIcon = styled.a`
  margin: 0 10px;
`;
const LeftIcon = styled.a`
  margin: 0 10px 0 0;
`;
const RightIcon = styled.a`
  margin: 0 0 0 10px;
`;
const IconPadding = styled.div`
  padding: 0 1rem;
  @media (max-width: 769px) {
    padding: 0;
  }
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  text-align: left;
  @media (max-width: 550px) {
    margin: 0;
  }
`;
