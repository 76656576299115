import { Helmet } from "react-helmet-async";

function SEO({ metadata }) {
  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      {metadata.href && <link rel="canonical" href={metadata.href} />}
    </Helmet>
  );
}

export default SEO;
