import React, { Suspense, useLayoutEffect } from "react";
import LoadingSpinner from "../../components/Spinners/LoadingSpinner";
import SEO from "../../components/SEO";
import Title from "../../components/Screens/Home/Title";
import Introduction from "../../components/Screens/Home/Introduction";
import WeAreLambda from "../../components/Screens/Home/WeAreLambda";
import metadata from "../../constants/metadata";

const LazySolutions = React.lazy(() =>
  import("../../components/Screens/Solutions/Solutions")
);
const LazyTestimonials = React.lazy(() =>
  import("../../components/Screens/Home/Testimonials")
);
const LazyCompanies = React.lazy(() =>
  import("../../components/Screens/Home/Companies")
);

function HomeScreen(props) {
  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);

  const homeMetadata = window.location.pathname.includes("contacto")
    ? metadata.contact
    : metadata.home;

  return (
    <>
      <SEO metadata={homeMetadata} />
      <Title />
      <Introduction />
      <WeAreLambda />
      <Suspense fallback={<LoadingSpinner />}>
        <LazySolutions />
      </Suspense>
      <Suspense fallback={<LoadingSpinner />}>
        <LazyTestimonials />
      </Suspense>
      <Suspense fallback={<LoadingSpinner />}>
        <LazyCompanies />
      </Suspense>
    </>
  );
}

export default HomeScreen;
