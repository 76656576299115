// COMPONENTS
import { useState } from "react";
import CustomizableButton from "../../Buttons/CustomizableButton";

// MODALS
import Contact from "../../../components/Modal/Contact";
import DataSent from "../../../components/Modal/DataSent";
import DataSentError from "../../Modal/DataSentError";
import Backdrop from "../../../components/Elements/Backdrop";

import { alternativeColor } from "../../../style/colors";

function TitleImage(props) {
  const [contactOpen, toggleContact] = useState(false);
  const [dataSentOpen, toggleDataSent] = useState(false);
  const [dataSentErrorOpen, toggleDataSentError] = useState(false);

  const type = props.type;
  const title = props.title;
  const subTitle = props.subTitle;
  const button = props.button;
  const image = props.image;

  return (
    <>
      {/*-------------------------------- MODALS --------------------------------*/}
      {/* DataSent Modal*/}
      <DataSent dataSentOpen={dataSentOpen} toggleDataSent={toggleDataSent} />
      {dataSentOpen && <Backdrop toggle={toggleDataSent} />}
      {/* DataSentError Modal*/}
      <DataSentError
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataSentError={toggleDataSentError}
      />
      {dataSentErrorOpen && <Backdrop toggle={toggleDataSentError} />}
      {/* Contact Modal*/}
      <Contact
        contactOpen={contactOpen}
        toggleContact={toggleContact}
        dataSentOpen={dataSentOpen}
        toggleDataSent={toggleDataSent}
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataErrorSent={toggleDataSentError}
        option={button.messageOption.option}
        message={button.messageOption.message}
      />
      {contactOpen && <Backdrop toggle={toggleContact} />}
      {/*------------------------------------------------------------------------*/}
      <div
        className="flex flexColumn flexSpaceCenter mobile-titleimage-padding"
        style={{
          backgroundImage: `url(${image.src})`,
          backgroundSize: "cover",
        }}
      >
        <div
          className="margin2T font-text radius6 gothamThin whiteColor mobile-model-padding"
          style={{
            backgroundColor: "transparent",
            border: "1px solid white",
            padding: "0.2% 1.5%",
          }}
        >
          {type.toUpperCase()}
        </div>
        <div className="margin2T">
          <h1 className="mobile-margin2T gothamBold font-title-solution whiteColor textCenter">
            {title}
          </h1>
          <p className="mobile-margin2T gothamLight font-text whiteColor textCenter">
            {subTitle}
          </p>
        </div>
        <div className="margin1B w100 flex flexColumn flexSpaceCenter">
          <CustomizableButton
            key={0}
            id={0}
            font={"gothamMedium font-button primaryColor mobile-button-padding"}
            title={button.title.toUpperCase()}
            isCallback={true}
            callBack={(id) => {
              toggleContact(!contactOpen);
            }}
            background={alternativeColor}
            border={`1px solid ${alternativeColor}`}
            padding={"0.5% 1.5%"}
            hover={{
              border: `1px solid ${alternativeColor}`,
              background: alternativeColor,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default TitleImage;
