function LineDivider(props) {
  const width = props.width;
  const border = props.border;
  const padding = props.padding;
  return (
    <div style={{ padding: padding }}>
      <div width={width} style={{ padding: "0px", borderBottom: border }} />
    </div>
  );
}

export default LineDivider;
