import { useState } from "react";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import CustomizableButton from "../../Buttons/CustomizableButton";
import ShowSolution from "../../Elements/ShowSolution";
import Solution from "../../Screens/Solutions/Solution";
import { defaultPath } from "../../../constants/availableSolutions";
import { getContent } from "../../../constants/languages/languages";
import { paddingStyle } from "../../../style/Responsive/paddingStyle";

import Img1 from "../../../assets/Soluciones/PlaneacionProduccion/img1.webp";
import Icon1 from "../../../assets/Soluciones/PlaneacionProduccion/icon.webp";

import Img2 from "../../../assets/Soluciones/PrediccionRenuncia/img1.webp";
import Icon2 from "../../../assets/Soluciones/PrediccionRenuncia/icon.webp";

import Img3 from "../../../assets/Soluciones/ClientesPotenciales/img2.webp";
import Icon3 from "../../../assets/Soluciones/ClientesPotenciales/icon.webp";

import Img4 from "../../../assets/Soluciones/SolucionesPersonalizadas/img2.webp";
import Icon4 from "../../../assets/Soluciones/SolucionesPersonalizadas/icon.webp";

import ProductionPlanning from "../../../screens/Solutions/Single/ProductionPlanning";
import ResignationPrediction from "../../../screens/Solutions/Single/ResignationPrediction";
import PotentialCustomers from "../../../screens/Solutions/Single/PotentialCustomers";
import CustomizedSolutions from "../../../screens/Solutions/Single/CustomizedSolutions";

import {
  primaryColor,
  secondaryColor,
  buttonBgColor,
} from "../../../style/colors";

const buttonStyle = {
  border: `1px solid ${primaryColor}`,
  background: "none",
  font: "gothamMedium font-button primaryColor",
};
const buttonSelectedStyle = {
  border: `1px solid ${buttonBgColor}`,
  background: buttonBgColor,
};
const descriptionClass = "gothamLight font-text blackColor textLeft";

function Solutions(props) {
  const language = useSelector((state) => state.language.language);
  const { width } = useWindowDimensions();
  const mobileWidth = 780;
  const isMobile = width < mobileWidth;
  const content = getContent(language);

  const [selected, setSelected] = useState(1);
  const [previous, setPrevious] = useState(null);

  const callBackFunction = (id) => {
    setPrevious(selected);
    setSelected(id);
  };

  const solutionsPreviewInfo = [
    {
      id: 1,
      title: content.home.solutions.available.productionPlanning.title,
      img: Img1,
      icon: Icon1,
      rightImg: false,
      button: {
        title: content.home.solutions.available.productionPlanning.button,
        border: "none",
        background: secondaryColor,
        font: "gothamBold font-button whiteColor",
      },
      description: (
        <div>
          <p className={descriptionClass}>
            {content.home.solutions.available.productionPlanning.description}
          </p>
          <br />
          <ol
            className={`${descriptionClass} mobile-section-horizontal-padding list-line-height`}
          >
            {content.home.solutions.available.productionPlanning.list.map(
              (item, index) => (
                <li key={index}>{item}</li>
              )
            )}
          </ol>
          <br />
        </div>
      ),
    },
    {
      id: 2,
      title: content.home.solutions.available.resignationPrediction.title,
      img: Img2,
      icon: Icon2,
      rightImg: false,
      button: {
        title: content.home.solutions.available.resignationPrediction.button,
        border: "none",
        background: secondaryColor,
        font: "gothamBold font-button whiteColor",
      },
      description: (
        <div>
          <p className={descriptionClass}>
            {content.home.solutions.available.resignationPrediction.description}
          </p>
          <br />
          <ol
            className={`${descriptionClass} mobile-section-horizontal-padding list-line-height`}
          >
            {content.home.solutions.available.resignationPrediction.list.map(
              (item, index) => (
                <li key={index}>{item}</li>
              )
            )}
          </ol>
          <br />
        </div>
      ),
    },
    {
      id: 3,
      title: content.home.solutions.available.potentialCostumers.title,
      img: Img3,
      icon: Icon3,
      rightImg: true,
      button: {
        title: content.home.solutions.available.potentialCostumers.button,
        border: "none",
        background: secondaryColor,
        font: "gothamBold font-button whiteColor",
      },
      description: (
        <div>
          <p className={descriptionClass}>
            {content.home.solutions.available.potentialCostumers.description[0]}
          </p>
          <br />
          <p className={descriptionClass}>
            {content.home.solutions.available.potentialCostumers.description[1]}
          </p>
          <br />
          <ol
            className={`${descriptionClass} mobile-section-horizontal-padding list-line-height`}
          >
            {content.home.solutions.available.potentialCostumers.list.map(
              (item, index) => (
                <li key={index}>{item}</li>
              )
            )}
          </ol>
          <br />
        </div>
      ),
    },
    {
      id: 4,
      title: content.home.solutions.available.customizedSolutions.title,
      img: Img4,
      icon: Icon4,
      rightImg: true,
      button: {
        title: content.home.solutions.available.customizedSolutions.button,
        border: "none",
        background: secondaryColor,
        font: "gothamBold font-button whiteColor",
      },
      description: (
        <div>
          <p className={descriptionClass}>
            {
              content.home.solutions.available.customizedSolutions
                .description[0]
            }
          </p>
          <br />
          <p className={descriptionClass}>
            {
              content.home.solutions.available.customizedSolutions
                .description[1]
            }
          </p>
          <br />
        </div>
      ),
    },
  ];

  const selectedInfo = solutionsPreviewInfo[selected - 1];

  const availableSolutions = [
    {
      id: 1,
      name: content.home.solutions.buttons[0],
      pathName: "planeacion_de_la_produccion",
      component: <ProductionPlanning />,
    },
    {
      id: 2,
      name: content.home.solutions.buttons[1],
      pathName: "prediccion_de_renuncias",
      component: <ResignationPrediction />,
    },
    {
      id: 3,
      name: content.home.solutions.buttons[2],
      pathName: "clientes_potenciales",
      component: <PotentialCustomers />,
    },
    {
      id: 4,
      name: content.home.solutions.buttons[3],
      pathName: "soluciones_personalizadas",
      component: <CustomizedSolutions />,
    },
  ];

  const solutions = [
    {
      id: 1,
      href: `${defaultPath}/${availableSolutions[0].pathName}`,
      title: availableSolutions[0].name,
    },
    {
      id: 2,
      href: `${defaultPath}/${availableSolutions[1].pathName}`,
      title: availableSolutions[1].name,
    },
    {
      id: 3,
      href: `${defaultPath}/${availableSolutions[2].pathName}`,
      title: availableSolutions[2].name,
    },
    {
      id: 4,
      href: `${defaultPath}/${availableSolutions[3].pathName}`,
      title: availableSolutions[3].name,
    },
  ];

  return (
    <div
      id="solutions"
      className="whiteBg mobile-section-horizontal-padding"
      style={paddingStyle}
    >
      <h1 className="gothamBold font-title primaryColor textCenter">
        {content.home.solutions.title}
      </h1>
      <p
        className="gothamLight font-text blackColor textCenter"
        style={{ padding: "0 5%" }}
      >
        {content.home.solutions.content[0]}
      </p>
      <br />
      <p
        className="gothamLight font-text blackColor textCenter"
        style={{ padding: "0 5%" }}
      >
        {content.home.solutions.content[1]}
      </p>
      <br />
      {!isMobile && (
        <div className="row">
          {solutions.map((elem) => (
            <CustomizableButton
              key={elem.id === previous ? 0 : elem.id}
              id={elem.id}
              font={buttonStyle.font}
              title={elem.title.toUpperCase()}
              isCallback={true}
              callBack={callBackFunction}
              background={
                selected === elem.id
                  ? buttonSelectedStyle.background
                  : buttonStyle.background
              }
              border={
                selected === elem.id
                  ? buttonSelectedStyle.border
                  : buttonStyle.border
              }
              hover={{
                border: `1px solid ${buttonBgColor}`,
                background: buttonBgColor,
              }}
            />
          ))}
        </div>
      )}
      {isMobile && (
        <div className="flex flexColumn">
          {solutions.map((elem) => (
            <CustomizableButton
              key={elem.id === previous ? 0 : elem.id}
              id={elem.id}
              font={buttonStyle.font}
              title={elem.title.toUpperCase()}
              isCallback={true}
              callBack={callBackFunction}
              background={selected === elem.id ? secondaryColor : buttonBgColor}
              border={
                selected === elem.id
                  ? `1px solid ${secondaryColor}`
                  : `1px solid ${buttonBgColor}`
              }
              hover={{
                border: `1px solid ${secondaryColor}`,
                background: secondaryColor,
              }}
              padding="5% 3%"
              margin="2% 8%"
            />
          ))}
        </div>
      )}
      <br />
      <ShowSolution
        title={selectedInfo.title}
        img={selectedInfo.img}
        icon={selectedInfo.icon}
        rightImg={selectedInfo.rightImg}
      >
        {selectedInfo.description}
      </ShowSolution>
      <Solution
        title={selectedInfo.button.title}
        font={selectedInfo.button.font}
        background={selectedInfo.button.background}
        border={selectedInfo.button.border}
        innerPadding={"2% 5%"}
        to={solutions[selected - 1].href}
        replace={false}
      />
    </div>
  );
}

export default Solutions;
