import axios from "axios";

// https://landing-page-rest-api-xp5t.onrender.com/
// http://localhost:5000/
export const baseURL = "https://landing-page-rest-api-xp5t.onrender.com/";
export const timeout = 10000;

export const HTTP_METHODS = Object.freeze({
  GET: Symbol(),
  PUT: Symbol(),
  POST: Symbol(),
  DELETE: Symbol(),
});

export const API = axios.create({
  baseURL: baseURL,
  timeout: timeout,
  headers: { "Content-Type": "application/json" },
});

export const FileAPI = () => {
  return axios.create({
    baseURL: baseURL,
    timeout: timeout,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const DownloadAPI = (url, _id) => {
  return axios.create({
    baseURL: `${baseURL}${url}/${_id}`,
    timeout: timeout,
    responseType: "blob",
  });
};
