import styled from "styled-components";
import { useParams } from "react-router-dom";
import { availableSolutions as available } from "../../constants/availableSolutions";
import NotFound from "../NotFound";

function getSolution(solutionName) {
  for (const i in available) {
    if (available[i].pathName === solutionName) {
      return available[i].component;
    }
  }
  return <NotFound />;
}

/* 
    Params:
        solution_name: Nombre de la solución
*/

function SolutionHandler(props) {
  const params = useParams();
  const solutionName = params.solution_name;
  return <Wrapper id={"initial"}>{getSolution(solutionName)}</Wrapper>;
}

const Wrapper = styled.section`
  width: 100%;
`;

export default SolutionHandler;
