import "./../../style/loadingSpinner.css";

function LoadingSpinner(props) {
  return (
    <div className="flexCenter">
      <div className=" lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
