import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23.335}
      height={23.335}
      {...props}
    >
      <g data-name="Group 99" fill="#fff">
        <path
          data-name="Rectangle 58"
          d="M21.213 0l2.121 2.121L2.121 23.335 0 21.213z"
        />
        <path
          data-name="Rectangle 59"
          d="M23.334 21.213l-2.121 2.121L-.001 2.121 2.121 0z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
