import { getContent } from "./languages/languages";
import ProductionPlanning from "../screens/Solutions/Single/ProductionPlanning";
import ResignationPrediction from "../screens/Solutions/Single/ResignationPrediction";
import PotentialCustomers from "../screens/Solutions/Single/PotentialCustomers";
import CustomizedSolutions from "../screens/Solutions/Single/CustomizedSolutions";

const content = getContent(localStorage.getItem("language") || "en");
/* import Outsourcing from "./screens/Solutions/Single/Outsourcing";
import Consultancies from "./screens/Solutions/Single/Consultancies";
import Pipelines from "./screens/Solutions/Single/Pipelines";
import Dashboards from "./screens/Solutions/Single/Dashboards";
import CompanyBuild from "./screens/Solutions/Single/CompanyBuild";
import PlatformDevelopment from "./screens/Solutions/Single/PlatformDevelopment"; */

export const availableSolutions = [
  {
    id: 1,
    name: content.home.solutions.buttons[0],
    pathName: "planeacion_de_la_produccion",
    component: <ProductionPlanning />,
  },
  {
    id: 2,
    name: content.home.solutions.buttons[1],
    pathName: "prediccion_de_renuncias",
    component: <ResignationPrediction />,
  },
  {
    id: 3,
    name: content.home.solutions.buttons[2],
    pathName: "clientes_potenciales",
    component: <PotentialCustomers />,
  },
  {
    id: 4,
    name: content.home.solutions.buttons[3],
    pathName: "soluciones_personalizadas",
    component: <CustomizedSolutions />,
  },
  /* {
    id: 5,
    name: "Outsourcing",
    pathName: "outsourcing",
    component: <Outsourcing />,
  },
  {
    id: 6,
    name: "Asesorías",
    pathName: "asesorias",
    component: <Consultancies />,
  },
  {
    id: 7,
    name: "Pipelines",
    pathName: "pipelines",
    component: <Pipelines />,
  },
  {
    id: 8,
    name: "Dashboards",
    pathName: "dashboards",
    component: <Dashboards />,
  },
  {
    id: 9,
    name: "Construcción de una empresa",
    pathName: "construccion-de-una-empresa",
    component: <CompanyBuild />,
  },
  {
    id: 10,
    name: "Desarrollo de plataformas",
    pathName: "desarrollo-de-plataformas",
    component: <PlatformDevelopment />,
  }, */
];

export const defaultPath = "/soluciones";
