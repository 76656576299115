// SUCCESS
export const OK = 200;
export const CREATED = 201;

// SERVER ERROR
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const INACTIVE_ELEMENT = 403;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const NOT_ACCEPTABLE = 406;
export const CONFLICT = 409;

// OUT OF SERVICE
export const SERVICE_UNAVAILABLE = 503;
