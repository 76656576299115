import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

// Assets
//import Arrow from "../../assets/arrow.webp";
//import Arrow from "../../assets/svg/Logo";

export default function ToUpArrow() {
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Wrapper
        className="radius8 flexCenter animate secondaryBg"
        style={
          y > 200
            ? {
                height: "6%",
                minHeight: "60px",
                maxHeight: "65px",
              }
            : { display: "none" }
        }
      >
        <div>
          <Link
            className="container flexCenter pointer"
            to="initial"
            smooth={true}
          >
            <ImgWrapper className="flexCenter">
              <p className="font30 whiteColor">&#8593;</p>
            </ImgWrapper>
          </Link>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 3%;
  min-width: 60px;
  max-width: 90px !important;
  position: fixed;
  bottom: 2%;
  right: 1%;
  z-index: 999;
  overflow: hidden;
  &:hover div {
    margin: 100%;
    border-radius: 1rem;
    background-color: #ffb530;
    transition: 0.5s ease;
  }
`;
const ImgWrapper = styled.div`
  width: 30px;
  padding: 0px;
  img {
    width: 30px;
    height: auto;
  }
  @media (max-width: 100px) {
    padding: 0;
  }
`;
