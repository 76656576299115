export const messageOptions = (content) => {
  return {
    lambda: { option: 1, message: "" },
    solutions: { option: 2, message: "" },
    advice: {
      option: 3,
      message: content.contact.defaultMessages.advice,
    },
    work: { option: 4, message: "" },
    productionPlanning: [
      {
        option: 5,
        message: content.contact.defaultMessages.productionPlanning,
      },
    ],
    resignationPrediction: [
      {
        option: 6,
        message: content.contact.defaultMessages.resignationPrediction[0],
      },
      {
        option: 3,
        message: content.contact.defaultMessages.resignationPrediction[1],
      },
    ],
    potentialCustomers: [
      {
        option: 7,
        message: content.contact.defaultMessages.potentialCustomers[0],
      },
      {
        option: 7,
        message: content.contact.defaultMessages.potentialCustomers[1],
      },
    ],
    customizedSolutions: [
      {
        option: 8,
        message: content.contact.defaultMessages.customizedSolutions[0],
      },
      {
        option: 8,
        message: content.contact.defaultMessages.customizedSolutions[1],
      },
      {
        option: 8,
        message: content.contact.defaultMessages.customizedSolutions[2],
      },
    ],
    other: { option: 9, message: "" },
  };
};
