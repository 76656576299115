// STYLED
import styled from "styled-components";

// COMPONENTS
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../constants/languages/languages";
import InfoImage from "../../components/Screens/Solutions/InfoImage";
import Info from "../../components/Screens/Solutions/Info";
import TimeLine from "../../components/Screens/WeAre/TimeLine";
import SEO from "../../components/SEO";
import metadata from "../../constants/metadata";

// ASSETS
import Img1 from "../../assets/WeAreLambda/img1.webp";
import Img2 from "../../assets/WeAreLambda/img2.webp";
import Icon1 from "../../assets/WeAreLambda/icon1.webp";
import Icon2 from "../../assets/WeAreLambda/icon2.webp";
import Icon3 from "../../assets/WeAreLambda/icon3.webp";

// COLORS
import { gradientBg, lightBg, whiteBg } from "../../style/colors";

// ALIGNS
import { textLeft, textCenter } from "../../style/aligns";

// CONSTANTS
const descriptionClass = "gothamLight font-text";
const boldClass = "gothamBold";

function WeAreScreen(props) {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  const title = {
    background: gradientBg,
    title: content.weAre.title.title,
    titleClass: "gothamBold font-title whiteColor",
    description: content.weAre.title.description,
    descriptionClass: `${descriptionClass} whiteColor`,
    align: textCenter,
  };

  const introduction = {
    background: whiteBg,
    title: "",
    titleClass: "",
    description: (
      <div>
        <p className={descriptionClass}>
          {content.weAre.banners[0].beforeHighlight}{" "}
          <span className={boldClass}>
            {content.weAre.banners[0].highlight}
          </span>{" "}
          {content.weAre.banners[0].afterHighlight}
        </p>
      </div>
    ),
    descriptionClass: descriptionClass,
    align: textCenter,
    padding: "3% 20%",
    isNotP: true,
  };

  const banners = [
    {
      id: 1,
      background: lightBg,
      txtAlign: textLeft,
      title: content.weAre.banners[1].title,
      description: (
        <div>
          <p className={descriptionClass}>
            {content.weAre.banners[1].content[0]}
          </p>
          <br />
          <p className={descriptionClass}>
            {content.weAre.banners[1].content[1]}
          </p>
        </div>
      ),
      rightImg: true,
      img: Img1,
      titleClass: "gothamBold font-title primaryColor",
    },
  ];

  const timeLine = {
    img: Img2,
    iconUp: true,
    icons: [Icon1, Icon2, Icon3],
    texts: [
      content.weAre.timeline[0],
      content.weAre.timeline[1],
      content.weAre.timeline[2],
    ],
  };

  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);

  return (
    <Wrapper id="initial">
      <SEO metadata={metadata.weAre} />
      <Info
        background={title.background}
        title={title.title}
        titleClass={title.titleClass}
        description={title.description}
        descriptionClass={title.descriptionClass}
        align={title.align}
        padding={"3% 20%"}
      />
      <Info
        background={introduction.background}
        title={introduction.title}
        titleClass={introduction.titleClass}
        description={introduction.description}
        descriptionClass={introduction.descriptionClass}
        align={introduction.align}
        padding={introduction.padding}
        isNotP={introduction.isNotP}
      />
      {banners.map((elem) => {
        return (
          <InfoImage
            key={elem.id}
            id={elem.id}
            background={elem.background}
            txtAlign={elem.txtAlign}
            title={elem.title}
            description={elem.description}
            rightImg={elem.rightImg}
            img={elem.img}
            titleClass={elem.titleClass}
            imgPadding={elem.imgPadding}
          />
        );
      })}
      <TimeLine
        img={timeLine.img}
        iconUp={timeLine.iconUp}
        icons={timeLine.icons}
        texts={timeLine.texts}
      />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0 5% 0;
`;

export default WeAreScreen;
